import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { Box, Container, Heading, Text, Image } from '@chakra-ui/react';
import Logo from './Logo';

const Maintenance = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.maintenanceMode) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  return (
    <Container backgroundColor={"#ede9e5"} borderRadius={"lg"} mt={4} pt={8} maxW="container.md" centerContent>
      <Helmet>
        <title>メンテナンス中です🙇‍♀️</title>
      </Helmet>
      <Logo />
      <Heading as="h1" size="xs" textAlign="center" mt="12px" mb="20px" color="gray.600">会員専用システム</Heading>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} my={4} backgroundColor={"#fdfdfd"} boxShadow="sm">
        <Text fontSize="lg" textAlign="center" mb={4} fontWeight={"bold"} color="gray.700">現在、メンテナンス中です。</Text>
        <Image src="/maintenance.jpg" alt="メンテナンス中のイラスト" borderRadius="md"/>
        <Text mt={4} fontSize={"xs"} color="gray.500">メンテナンス完了までしばらくお待ちくださいませ</Text>
      </Box>
    </Container>
  );
};

export default Maintenance;
