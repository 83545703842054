import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Container,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
} from '@chakra-ui/react';
import { firestore, collection, doc, updateDoc } from '../firebaseConfig';

const AddShiftPage = () => {
  const { currentUser } = useAuth();
  const { studioId } = useParams();

  const [day, setDay] = useState('月');
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const [instructorId, setInstructorId] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const studioRef = doc(collection(firestore, 'studios'), studioId);

    const newShifts = {};
    for (let hour = parseInt(startHour); hour <= parseInt(endHour); hour++) {
      const shiftKey = `${day}${hour}`;
      if (!newShifts[`shifts.${shiftKey}`]) {
        newShifts[`shifts.${shiftKey}`] = [];
      }
      newShifts[`shifts.${shiftKey}`].push(instructorId);
    }
    await updateDoc(studioRef, newShifts);

    setDay('月');
    setStartHour('');
    setEndHour('');
    setInstructorId('');
    alert('シフトが追加されました！');
  };

  const groupShiftsByInstructor = shifts => {
    const instructorShifts = {};

    Object.entries(shifts).forEach(([key, value]) => {
      const [day, hour] = key.match(/([^\d]+)(\d+)/).slice(1, 3);
      value.forEach(instructorId => {
        if (!instructorShifts[instructorId]) {
          instructorShifts[instructorId] = {
            月: [],
            火: [],
            水: [],
            木: [],
            金: [],
            土: [],
            日: [],
          };
        }
        instructorShifts[instructorId][day].push(parseInt(hour));
      });
    });

    return instructorShifts;
  };

  const instructorShifts = groupShiftsByInstructor(
    currentUser.studio.shifts || {}
  );

  const hours = Array.from({ length: 21 - 9 + 1 }, (_, i) => i + 9);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="md" textAlign="center" mt="0px" mb="20px">
        幡ヶ谷スタジオ受付枠設定画面
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>インストラクター</FormLabel>
              <Select
                value={instructorId}
                onChange={e => setInstructorId(e.target.value)}
              >
                <option key={'0'} value={''}>
                  {'選択してください'}
                </option>
                {Object.entries(currentUser.studio.instructors || {}).map(
                  ([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>曜日</FormLabel>
              <Select value={day} onChange={e => setDay(e.target.value)}>
                <option value="月">月</option>
                <option value="火">火</option>
                <option value="水">水</option>
                <option value="木">木</option>
                <option value="金">金</option>
                <option value="土">土</option>
                <option value="日">日</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>開始時間</FormLabel>
              <Input
                type="number"
                value={startHour}
                onChange={e => setStartHour(e.target.value)}
                placeholder="例: 9"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>終了時間</FormLabel>
              <Input
                type="number"
                value={endHour}
                onChange={e => setEndHour(e.target.value)}
                placeholder="例: 16"
              />
            </FormControl>
            <Button colorScheme="blue" type="submit">
              追加
            </Button>
          </VStack>
        </form>
      </Box>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {Object.entries(instructorShifts).map(([instructorId, days]) => (
          <Box key={instructorId} mb={6}>
            <Box fontWeight="bold" mb={2}>
              {currentUser.studio.instructors[instructorId]}さんの予約受付枠
            </Box>
            <Table variant="simple" size="xs">
              <Thead>
                <Tr>
                  <Th>開始</Th>
                  <Th>月</Th>
                  <Th>火</Th>
                  <Th>水</Th>
                  <Th>木</Th>
                  <Th>金</Th>
                  <Th>土</Th>
                  <Th>日</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hours.map(hour => (
                  <Tr key={hour}>
                    <Td>{hour}時</Td>
                    {['月', '火', '水', '木', '金', '土', '日'].map(day => (
                      <Td key={day}>{days[day].includes(hour) ? '○' : '-'}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default AddShiftPage;
