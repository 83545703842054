import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Container,
  Text,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  IconButton,
  Select,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Thead,
  Textarea,
  Divider,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from './LoadingModal';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faAdd } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

const Reports = () => {
  const { currentUser } = useAuth();
  const [reservations, setReservations] = useState([]);
  const [fees, setFees] = useState({
    lesson: 2000,
    meeting: 1000,
    training: 1000,
    outsource: 2000,
    train: 500,
    train_upper: false,
  });
  const [fixes, setFixes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const {
    isOpen: isInvoiceOpen,
    onOpen: onInvoiceOpen,
    onClose: onInvoiceClose,
  } = useDisclosure();
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [report, setReport] = useState('');
  const [year, setYear] = useState(dayjs().year());
  const [month, setMonth] = useState(dayjs().month() + 1); // month() は 0 から始まるため、+1 しています
  const [selectedInstructor, setSelectedInstructor] = useState(
    // currentUser.admin ? '' : 'Iuki9uvQEecboMMwDHIzqB1YbYt2'
    currentUser.admin ? '' : currentUser.uid
  );

  const [menu, setMenu] = useState([
    { machine: '', purpose: '' }, // デフォルトのメニュー1
    { machine: '', purpose: '' }, // デフォルトのメニュー2
  ]);

  // モーダルを閉じる際にリセットする関数
  const resetForm = () => {
    setMenu([
      { machine: '', purpose: '' }, // デフォルトのメニュー1
      { machine: '', purpose: '' }, // デフォルトのメニュー2
    ]);
    setReport('');
    setSelectedReservation(null);
  };

  useEffect(() => {
    setYear(dayjs().year());
  }, []);

  const summary = {
    block: '【空室】',
    temporary: '【確保】',
    training: '【研修】',
    outsource: '【作業】',
    meeting: '【会議】',
    trial: '【体験】',
    plan: '【予約】',
    once: '【予約】',
  };

  useEffect(() => {
    const fetchFees = async () => {
      if (!selectedInstructor) return;

      const startOfMonth = dayjs()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toDate(); // タイムスタンプ型に変換
      const endOfMonth = dayjs()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .toDate(); // タイムスタンプ型に変換

      const feesRef = collection(
        firestore,
        'users',
        selectedInstructor,
        'fees'
      );
      const q = query(
        feesRef,
        where('startAt', '<=', endOfMonth),
        where('finishAt', '>=', startOfMonth)
      );
      const feesSnapshot = await getDocs(q);
      if (!feesSnapshot.empty) {
        const feeData = feesSnapshot.docs[0].data();
        setFees({
          lesson: feeData.lesson,
          meeting: feeData.meeting,
          training: feeData.training,
          outsource: feeData.outsource,
          train: feeData.train || 0,
          train_upper: feeData.train_upper || false,
          address: feeData.address,
        });
        setFixes(feeData.fix);
      } else {
        setFees(null); // データが存在しない場合の処理
        setFixes([]);
      }
    };

    fetchFees();
  }, [selectedInstructor, year, month]);

  const fetchReservations = async () => {
    setLoading(true);
    const reservationsRef = collection(firestore, 'reservations');

    const startOfMonth = dayjs()
      .year(year)
      .month(month - 1)
      .startOf('month')
      .format('YYYY-MM-DDTHH:mm');
    const endOfMonth = dayjs()
      .year(year)
      .month(month - 1)
      .endOf('month')
      .format('YYYY-MM-DDTHH:mm');

    const q = query(
      reservationsRef,
      where('studioId', '==', currentUser.studioId),
      where('instructorId', '==', selectedInstructor),
      where('startDate', '>=', startOfMonth),
      where('startDate', '<=', endOfMonth),
      where('active', '==', true)
    );

    const reservationsSnapshot = await getDocs(q);
    const allReservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    const filteredReservations = allReservations
      .filter(
        reservation =>
          reservation.type !== 'block' && reservation.type !== 'temporary'
      )
      .sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    setReservations(filteredReservations);
    setLoading(false);
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, selectedInstructor]);

  if (loading) return <LoadingModal text="読み込み中..." />;

  const getReservationColor = type => {
    switch (type) {
      case 'temporary':
        return 'pink.200';
      case 'block':
        return 'gray.200';
      case 'outsource':
        return 'purple.300';
      case 'training':
        return 'green.200';
      case 'meeting':
        return 'yellow.200';
      case 'trial':
        return 'red.300';
      default:
        return 'blue.200';
    }
  };

  const renderReservations = () => {
    const grouped = reservations.reduce((acc, reservation) => {
      const date = dayjs(reservation.startDate).format('YYYY-MM-DD');
      if (!acc[date]) acc[date] = [];
      acc[date].push(reservation);
      return acc;
    }, {});

    return Object.keys(grouped).length > 0 ? (
      Object.keys(grouped).map(date => (
        <Box key={date} mb={4}>
          <Text fontSize="md" fontWeight="bold" mb={2}>
            {dayjs(date).format('YYYY年MM月DD日')}
          </Text>
          {grouped[date]
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .map(reservation => (
              <Flex
                borderWidth="0px"
                borderRadius="xl"
                overflow="hidden"
                py={2}
                pl={1}
                pr={3}
                mt={2}
                bg={getReservationColor(reservation.type)}
                justifyContent="space-between"
                alignItems="center"
                key={reservation.id}
              >
                <Box>
                  <Text fontSize="sm">
                    {summary[reservation.type]}　
                    {reservation.startDate.slice(11, 16)} -{' '}
                    {reservation.endDate.slice(11, 16)}
                  </Text>
                  <Text fontSize="xs">
                    　
                    {reservation.description && currentUser.instructor
                      ? reservation.description +
                        '（' +
                        currentUser.studio.instructors[
                          reservation.instructorId
                        ] +
                        '）'
                      : currentUser.belongStudioIds[reservation.studioId] +
                        '（' +
                        currentUser.studio.instructors[
                          reservation.instructorId
                        ] +
                        '）'}
                  </Text>
                </Box>
                {[
                  'plan',
                  'once',
                  'trial',
                  'meeting',
                  'training',
                  'outsource',
                ].includes(reservation.type) &&
                  !reservation.reported && (
                    <Box display="flex" alignItems="center">
                      <IconButton
                        aria-label="レポートを送信"
                        icon={<FontAwesomeIcon icon={faAdd} />}
                        size="sm"
                        variant={'outlineBlack'}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedReservation(reservation);
                          onOpen();
                        }}
                      />
                    </Box>
                  )}
                {reservation.reported && (
                  <Box display="flex" alignItems="center">
                    <Text fontSize="xs" color={'gray'}>
                      報告済み
                    </Text>
                    <IconButton
                      aria-label="レポートを確認"
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      size="sm"
                      ml={2}
                      variant={'outlineBlack'}
                      onClick={() => {
                        setSelectedReservation(reservation);
                        onViewOpen();
                      }}
                    />
                  </Box>
                )}
              </Flex>
            ))}
        </Box>
      ))
    ) : (
      <Text textAlign="center">現在の選択した月に予定はありません。</Text>
    );
  };

  const calculateHours = reservations => {
    return reservations.reduce((total, reservation) => {
      const start = dayjs(reservation.startDate);
      const end = dayjs(reservation.endDate);
      return total + end.diff(start, 'hour', true); // true to return float
    }, 0);
  };

  const totalLessons = reservations.filter(r =>
    ['plan', 'once', 'trial'].includes(r.type)
  ).length;
  const totalMeetings = calculateHours(
    reservations.filter(r => r.type === 'meeting')
  );
  const totalTrainings = calculateHours(
    reservations.filter(r => r.type === 'training')
  );
  const totalWorks = calculateHours(
    reservations.filter(r => r.type === 'outsource')
  );

  const totalReportedLessons = reservations.filter(
    r => ['plan', 'once', 'trial'].includes(r.type) && r.reported
  ).length;
  const totalReportedMeetings = calculateHours(
    reservations.filter(r => r.type === 'meeting' && r.reported)
  );
  const totalReportedTrainings = calculateHours(
    reservations.filter(r => r.type === 'training' && r.reported)
  );
  const totalReportedWorks = calculateHours(
    reservations.filter(r => r.type === 'outsource' && r.reported)
  );

  const uniqueAttendanceDates = [
    ...new Set(
      reservations
        .filter(r => ['plan', 'once'].includes(r.type))
        .map(r => dayjs(r.startDate).format('YYYY-MM-DD'))
    ),
  ].length;

  const trainFee = fees
    ? fees?.train_upper
      ? Math.min(uniqueAttendanceDates * fees?.train, 10000)
      : uniqueAttendanceDates * fees?.train
    : 0;

  const invoice = fees
    ? {
        lesson: totalReportedLessons * fees?.lesson,
        meeting: totalReportedMeetings * fees?.meeting,
        training: totalReportedTrainings * fees?.training,
        work: totalReportedWorks * fees?.outsource,
        attendance: trainFee,
        total:
          totalReportedLessons * fees?.lesson +
          totalReportedMeetings * fees?.meeting +
          totalReportedTrainings * fees?.training +
          totalReportedWorks * fees?.outsource +
          trainFee,
      }
    : {
        lesson: 0,
        meeting: 0,
        training: 0,
        work: 0,
        attendance: 0,
        total: 0,
      };

  fixes.forEach(fix => {
    invoice.total += fix.price;
  });

  const handleMenuChange = (index, field, value) => {
    const newMenu = [...menu];
    newMenu[index][field] = value;
    setMenu(newMenu);
  };

  const addMenu = () => {
    setMenu([...menu, { machine: '', purpose: '' }]);
  };

  // メニューの削除処理
  const removeMenu = index => {
    if (!window.confirm('メニュー' + (index + 1) + 'を本当に削除しますか？'))
      return;
    const newMenu = menu.filter((_, i) => i !== index);
    setMenu(newMenu);
  };

  // バリデーションチェック
  const isSubmitDisabled = () => {
    if (
      menu.some(m => m.machine === '') || // いずれかのメニューでマシンが選択されていない
      report.trim() === '' // メモが入力されていない
    ) {
      window.alert('存在するメニューとメモの入力は必須です。');
      return true;
    } else {
      if (
        report.length < 40 // メモが入力されていない
      ) {
        window.alert(
          'メモを他のインストラクターが読んでも伝わるようにもう少し具体的に書いてください🤲'
        );
        return true;
      }

      if (menu.some(m => m.purpose.length < 10)) {
        window.alert(
          'メニューの報告はお客様も目を通す可能性があります。何のためにやったメニューかなど、具体的かつ丁寧にお願いします🙌'
        );
        return true;
      }

      return false;
    }
  };

  const handleSubmitReport = async () => {
    if (!isSubmitDisabled()) {
      console.log('レポート送信:', { menu, report });
      const reservationRef = doc(
        firestore,
        'reservations',
        selectedReservation.id
      );
      const userReportsRef = collection(
        firestore,
        'users',
        selectedReservation.userId,
        'reports'
      );

      await addDoc(userReportsRef, {
        report,
        menu, // メニューを保存
        reservationId: selectedReservation.id,
        timestamp: new Date().toISOString(),
      });

      await updateDoc(reservationRef, {
        report,
        menu,
        reported: true,
      })
        .then(() => {
          const updatedReservations = reservations.map(r =>
            r.id === selectedReservation.id
              ? { ...r, report, reported: true }
              : r
          );
          setReservations(updatedReservations);
          resetForm();
          onClose();
          window.location.reload();
        })
        .catch(error => {
          console.error('レポートの送信に失敗しました: ', error);
        });
    }
  };
  // モーダルを閉じるときにリセットする
  const handleClose = () => {
    resetForm(); // フォームのリセット
    onClose(); // モーダルを閉じる
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        mt={6}
        p={4}
        w="100%"
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Flex justifyContent="center" mb={4}>
          <Text fontSize="xl" fontWeight="bold">
            {year}年
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb={4}>
          {/* 前の月ボタン */}
          <Button
            onClick={() => {
              if (month === 1) {
                setMonth(12);
                setYear(prevYear => (prevYear > 2024 ? prevYear - 1 : 2024));
              } else {
                setMonth(month - 1);
              }
            }}
          >
            前の月
          </Button>

          <Text fontSize="xl" fontWeight="bold">
            {month}月
          </Text>

          {/* 次の月ボタン */}
          <Button
            onClick={() => {
              if (month === 12) {
                setMonth(1);
                setYear(prevYear => prevYear + 1); // 年を進める
              } else {
                setMonth(month + 1);
              }
            }}
          >
            次の月
          </Button>
        </Flex>

        {currentUser.admin && (
          <Box mb={4}>
            <Select
              placeholder="インストラクターを選択"
              value={selectedInstructor}
              onChange={e => setSelectedInstructor(e.target.value)}
            >
              {Object.entries(currentUser.studio.instructors).map(
                ([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                )
              )}
            </Select>
          </Box>
        )}
        {fees && Object.keys(fees).length > 0 ? (
          <>
            <Box mb={4}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th style={{ textAlign: 'center' }}></Th>
                    <Th style={{ textAlign: 'center' }}>指導</Th>
                    <Th style={{ textAlign: 'center' }}>会議</Th>
                    <Th style={{ textAlign: 'center' }}>研修</Th>
                    <Th style={{ textAlign: 'center' }}>作業</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Th style={{ textAlign: 'center' }}>時間</Th>
                    <Td style={{ textAlign: 'center' }}>{totalLessons}</Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalMeetings.toFixed(0)}
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalTrainings.toFixed(0)}
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalWorks.toFixed(0)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th style={{ textAlign: 'center' }}>報告</Th>
                    <Td style={{ textAlign: 'center' }}>
                      {totalReportedLessons}
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalReportedMeetings.toFixed(0)}
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalReportedTrainings.toFixed(0)}
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {totalReportedWorks.toFixed(0)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
                業務報告の数で報酬が計算されます🙆‍♀️
              </Text>
              <Text
                color={''}
                fontSize="sm"
                my={2}
                textAlign={'center'}
                fontWeight={'bold'}
              >
                <Button
                  mt={1}
                  size="xs"
                  variant="stylishBlack"
                  onClick={onInvoiceOpen}
                >
                  請求書を見る
                </Button>
              </Text>
            </Box>
            <Box maxH="60vh" overflowY="auto">
              {renderReservations()}
            </Box>
          </>
        ) : (
          <Text textAlign="center">データがありません。</Text>
        )}
      </Box>

      <BackButton />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>
            {selectedReservation?.description} さんのレッスン報告
          </ModalHeader>{' '}
          {/* 選択した予約のユーザー名を表示 */}
          <ModalBody>
            {menu.map((m, index) => (
              <>
                <Text fontSize="sm" ml={1} fontWeight={600} mb={2}>
                  メニュー{index + 1}
                </Text>
                <Flex>
                  <Select
                    placeholder="マシンを選択"
                    value={m.machine}
                    onChange={e =>
                      handleMenuChange(index, 'machine', e.target.value)
                    }
                    mr={2}
                  >
                    <option value="マット">マット</option>
                    <option value="タワーー">タワー</option>
                    <option value="リフォーマー">リフォーマー</option>
                    <option value="バレル">バレル</option>
                    <option value="チェア">チェア</option>
                  </Select>
                </Flex>
                <Textarea
                  placeholder="ワーク内容と実施の目的"
                  value={m.purpose}
                  onChange={e =>
                    handleMenuChange(index, 'purpose', e.target.value)
                  }
                  mt={2}
                />
                <Text fontSize="xs" color="gray.500" my={1}>
                  お客様も見るため、その前提で書いてください🙌
                </Text>
                {/* メニュー削除ボタン */}
                <Button
                  size="xs"
                  colorScheme="red"
                  display={'inline-block'}
                  onClick={() => removeMenu(index)}
                >
                  削除
                </Button>

                <Divider my={4} />
              </>
            ))}
            <Button size="sm" onClick={addMenu}>
              メニュー入力欄を追加
            </Button>
            <Textarea
              placeholder="お客様と話した内容のメモ ✍️"
              value={report}
              onChange={e => setReport(e.target.value)}
              mt={4}
            />
            <Text fontSize="xs" color="gray.500" my={1}>
              こちらはお客様に表示されない内容です🙅‍♀️
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="blue"
              mr={2}
              onClick={handleSubmitReport}
            >
              送信
            </Button>
            <Button size="sm" variant="ghost" onClick={handleClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* レポート確認モーダル */}
      <Modal isOpen={isViewOpen} onClose={onViewClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>
            {selectedReservation?.description} さんのレッスン報告
          </ModalHeader>
          <ModalBody>
            <Text fontSize="lg" fontWeight="bold">
              実施したワーク
            </Text>
            {selectedReservation?.menu?.map((m, index) => (
              <Box key={index} mb={4}>
                <Text fontSize="sm" fontWeight="bold" my={2}>
                  メニュー{index + 1}: {m.machine}
                </Text>
                <Text fontSize="sm" my={2} style={{ whiteSpace: 'pre-wrap' }}>
                  {m.purpose}
                </Text>
                <Divider my={2} />
              </Box>
            ))}
            <Text fontSize="lg" fontWeight="bold" mt={4}>
              メモ
            </Text>
            <Text style={{ whiteSpace: 'pre-wrap' }}>
              {selectedReservation?.report}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="ghost" onClick={onViewClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isInvoiceOpen} onClose={onInvoiceClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={2} px={-8}>
          <ModalHeader textAlign={'center'} mt={4}>
            {year}年{month}月の給与明細
          </ModalHeader>
          <ModalBody>
            <Text
              fontSize="md"
              color={'teal'}
              mb={4}
              ml={2}
              textAlign={'center'}
            >
              STUDIO medy Instructor Compensation
            </Text>
            <Text fontSize="sm" fontWeight="bold" mb={4} ml={2}>
              Invoice（from{' '}
              {currentUser?.studio.instructors[selectedInstructor]} to medy）
            </Text>
            <Text fontSize="xs" fontWeight="" mt={4} ml={2}>
              　{fees?.address?.split(' ')[0]}
              <br />　{fees?.address?.split(' ').slice(1).join(' ')}
              <br />
              <br />
            </Text>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th colSpan={3}>報酬</Th>
                </Tr>
                <Tr>
                  <Th alignContent={'center'} pl={6}>
                    指導
                  </Th>
                  <Td alignContent={'center'}>
                    {fees?.lesson?.toLocaleString()} x {totalReportedLessons}
                  </Td>
                  <Td style={{ float: 'right' }}>
                    {invoice?.lesson?.toLocaleString()} 円
                  </Td>
                </Tr>
                <Tr>
                  <Th alignContent={'center'} pl={6}>
                    作業
                  </Th>
                  <Td alignContent={'center'}>
                    {fees?.outsource?.toLocaleString()} x {totalReportedWorks}
                  </Td>
                  <Td style={{ float: 'right' }}>
                    {invoice?.work?.toLocaleString()} 円
                  </Td>
                </Tr>
                <Tr>
                  <Th colSpan={3}>手当</Th>
                </Tr>
                <Tr>
                  <Th alignContent={'center'} pl={6}>
                    会議
                  </Th>
                  <Td alignContent={'center'}>
                    {fees?.meeting?.toLocaleString()} x {totalReportedMeetings}
                  </Td>
                  <Td style={{ float: 'right' }}>
                    {invoice?.meeting?.toLocaleString()} 円
                  </Td>
                </Tr>
                <Tr>
                  <Th alignContent={'center'} pl={6}>
                    研修
                  </Th>
                  <Td alignContent={'center'}>
                    {fees?.training?.toLocaleString()} x{' '}
                    {totalReportedTrainings}
                  </Td>
                  <Td style={{ float: 'right' }}>
                    {invoice?.training?.toLocaleString()} 円
                  </Td>
                </Tr>
                {fees?.train > 0 && (
                  <Tr>
                    <Th alignContent={'center'} pl={6}>
                      出勤
                    </Th>
                    <Td alignContent={'center'}>
                      {fees?.train?.toLocaleString()} x {uniqueAttendanceDates}
                    </Td>
                    <Td style={{ float: 'right' }}>
                      {invoice?.attendance?.toLocaleString()} 円
                    </Td>
                  </Tr>
                )}
                {fixes.length > 0 && (
                  <>
                    <Tr>
                      <Th colSpan={3}>固定</Th>
                    </Tr>
                    {fixes.map((fix, index) => (
                      <Tr key={index}>
                        <Th colSpan={2}>　{fix.name}</Th>
                        <Td style={{ float: 'right' }}>
                          {fix?.price?.toLocaleString()} 円
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
                <Tr>
                  <Th color={'black'}>合計</Th>
                  <Td></Td>
                  <Td style={{ float: 'right' }} fontWeight={'bold'}>
                    {invoice?.total?.toLocaleString()} 円
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Text fontSize="xs" fontWeight="" mt={4} ml={2}>
              <br />※ 振込予定日は{month === 12 ? year + 1 : year}年
              {month === 12 ? 1 : month + 1}月20~25日
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="ghost" onClick={onInvoiceClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Reports;
