import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Text,
  Container,
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Radio,
  RadioGroup,
  VStack,
  Input,
  Select,
} from '@chakra-ui/react';
import { useSwipeable } from 'react-swipeable';
import BackButton from './BackButton';
import LoadingModal from '../components/LoadingModal';
import Logo from './Logo';
import {
  firestore,
  doc,
  collection,
  addDoc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from '../firebaseConfig';
import { sendDiscordNotification } from '../utils/discord';

const AddReservation = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reserving, setReserving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fetching2, setFetching2] = useState(false);
  const [temporary, setTemporary] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [availableInstructors, setAvailableInstructors] = useState([]);
  const [dateOffset, setDateOffset] = useState(1); // 明日以降を表示するために初期値を1に設定
  const [isAllInstructorsTab, setIsAllInstructorsTab] = useState(true); // 全員タブを初期値に設定
  const [selectedUser, setSelectedUser] = useState(null);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNoTicketsOpen,
    onOpen: onNoTicketsOpen,
    onClose: onNoTicketsClose,
  } = useDisclosure();

  const getNextDays = offset => {
    const days = [];
    const today = new Date();
    today.setDate(today.getDate() + offset);
    for (let i = 0; i < 3; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const combineDateTime = (date, hour) => {
    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
    return `${dateStr}T${String(hour).padStart(2, '0')}:00`;
  };

  const days = getNextDays(dateOffset);
  const hours = Array.from({ length: 21 - 9 + 1 }, (_, i) => i + 9);

  useEffect(() => {
    const fetchReservations = async () => {
      setFetching(true);
      const reservationsRef = collection(firestore, 'reservations');
      const q = query(
        reservationsRef,
        where('studioId', '==', currentUser?.studioId),
        where('active', '==', true),
        where('startDate', '>=', new Date().toISOString())
      );
      const querySnapshot = await getDocs(q);
      const reservations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReservations(reservations);
      setFetching(false);
    };

    const fetchUsers = async () => {
      setFetching2(true);
      const users = [];
      const usersRef = collection(firestore, 'users');
      const q = query(
        usersRef,
        where('studioId', '==', currentUser.studioId),
        where('active', '==', true),
        where('status', '==', true)
      );
      const querySnapshot = await getDocs(q);

      for (const userDoc of querySnapshot.docs) {
        const userData = userDoc.data();
        const ticketsRef = collection(userDoc.ref, 'tickets');
        const ticketQuery = query(
          ticketsRef,
          where('used', '==', false),
          where(
            'expire',
            '>=',
            new Date(new Date().setDate(new Date().getDate() + 1))
          )
        );
        const ticketSnapshot = await getDocs(ticketQuery);

        const validTickets = ticketSnapshot.docs.map(doc => doc.data());
        users.push({
          id: userDoc.id,
          ...userData,
          validTicketCount: validTickets.length,
        });
      }

      setAvailableUsers(users);
      setFilteredUsers(users);
      setFetching2(false);
    };

    fetchReservations();
    if (currentUser?.instructor) {
      fetchUsers();
    }
  }, [
    currentUser?.studioId,
    currentUser?.instructor,
    currentUser?.studio.users,
  ]);

  const isHourReserved = (date, hour) => {
    return reservations.some(reservation => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      return (
        reservationStart.toDateString() === date.toDateString() &&
        hour >= reservationStart.getHours() &&
        hour < reservationEnd.getHours()
      );
    });
  };

  const isShiftAvailable = (date, hour, instructorId) => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
    const shiftKey = `${weekday}${hour}`;
    return (
      currentUser.studio.shifts[shiftKey] &&
      currentUser.studio.shifts[shiftKey].includes(instructorId)
    );
  };

  const isFixedSlot = (day, hour) => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'][day.getDay()];
    return currentUser.studio.fixies.some(
      fixie => fixie.weekday === weekday && fixie.start === hour
    );
  };

  const formatDateTime = (date, hour) => {
    const day = new Date(date);
    const formattedDate = `${day.getFullYear()}年${
      day.getMonth() + 1
    }月${day.getDate()}日`;
    return `${formattedDate} ${hour}:00`;
  };

  const handleReserveClick = (date, hour, instructorId) => {
    console.log(selectedUser);
    if (currentUser.instructor && !selectedUser) {
      alert('予約を取る会員を選択してください。');
      return;
    }
    if (
      !isFixedSlot(date, hour) &&
      ((isAllInstructorsTab &&
        instructorIds.some(
          id => isShiftAvailable(date, hour, id) && !isHourReserved(date, hour)
        )) ||
        (!isAllInstructorsTab &&
          isShiftAvailable(date, hour, instructorId) &&
          !isHourReserved(date, hour)))
    ) {
      setSelectedDateTime({ date, hour });
      if (!isAllInstructorsTab) {
        setSelectedInstructor(instructorId);
      }
      setAvailableInstructors(
        isAllInstructorsTab
          ? instructorIds.filter(id => isShiftAvailable(date, hour, id))
          : [instructorId]
      );
      onOpen();
    }
  };

  const handleConfirmReservation = async () => {
    const userId = selectedUser || currentUser.uid;
    const userName = selectedUserName || currentUser.fullName;

    let validTickets = [];

    if (currentUser.instructor && selectedUser) {
      const selectedUserDoc = await getDoc(
        doc(firestore, 'users', selectedUser)
      );
      if (!selectedUserDoc.exists) {
        console.error('Selected user does not exist');
        return;
      }

      const ticketsRef = collection(selectedUserDoc.ref, 'tickets');
      const ticketQuery = query(
        ticketsRef,
        where('used', '==', false),
        where(
          'expire',
          '>=',
          new Date(new Date().setDate(new Date().getDate() + 1))
        )
      );
      const ticketSnapshot = await getDocs(ticketQuery);
      validTickets = ticketSnapshot.docs.map(doc => doc.data());

      if (validTickets.length === 0 && !temporary) {
        onNoTicketsOpen();
        return;
      }
    } else {
      // インストラクターでない場合はcurrentUserからチケットを取得
      validTickets = currentUser.tickets.filter(
        ticket =>
          !ticket.used &&
          new Date(ticket.expire.toDate()).toISOString().slice(0, 10) >=
            new Date().toISOString().slice(0, 10)
      );

      console.log('有効なチケット:', validTickets);

      if (validTickets.length === 0 && !temporary) {
        alert(`有効なチケットがありません。`);
        return;
      }
    }

    setReserving(true);
    const { date, hour } = selectedDateTime;
    const startDateTime = combineDateTime(date, `${parseInt(hour)}`);
    const endDateTime = combineDateTime(date, `${parseInt(hour) + 1}`);

    const reservationsRef = collection(firestore, 'reservations');
    const q = query(
      reservationsRef,
      where('startDate', '<=', endDateTime),
      where('active', '==', true)
    );
    const querySnapshot = await getDocs(q);
    const conflicts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    const isConflict = conflicts.some(reservation => {
      if (
        startDateTime < reservation.endDate &&
        endDateTime > reservation.startDate
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (isConflict) {
      alert('この時間帯には既に予約があります。');
      setReserving(false);
      return;
    }

    let reservationId = null;
    try {
      const now = new Date();
      const nowStr = now.toISOString();
      const userDocRef = doc(firestore, 'users', userId);
      const ticketsRef = collection(userDocRef, 'tickets');
      const ticketQuery = query(
        ticketsRef,
        where('used', '==', false),
        where(
          'expire',
          '>=',
          new Date(new Date().setDate(new Date().getDate() + 1))
        )
      );
      const ticketSnapshot = await getDocs(ticketQuery);
      const ticketToUse = ticketSnapshot.docs[0]; // 有効なチケットの最初の一つを使用

      if (!temporary) {
        await updateDoc(ticketToUse.ref, { used: true });
      }

      const calendarEvent = {
        calendarId: `${currentUser?.studio?.slug}@studio-medy.com`,
        event: {
          summary:
            (temporary ? '【確保】' : '【予約】') +
            userName +
            '（' +
            currentUser.studio.instructors[
              selectedInstructor || availableInstructors[0]
            ] +
            '）',
          location: currentUser?.studio?.name,
          description: currentUser.instructor
            ? `${
                currentUser.studio.instructors[currentUser.uid] || '管理者'
              }がアプリから追加（${nowStr}）`
            : `ご本人がアプリから追加（${nowStr}）`,
          colorId: temporary ? 4 : 7,
          start: {
            dateTime: `${startDateTime}:00+09:00`,
            timeZone: 'Asia/Tokyo',
          },
          end: {
            dateTime: `${endDateTime}:00+09:00`,
            timeZone: 'Asia/Tokyo',
          },
        },
      };

      const response = await fetch(
        'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
        {
          method: 'POST',
          body: JSON.stringify(calendarEvent),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        console.error(
          'Googleカレンダーへのイベント追加に失敗しました',
          response
        );
        throw new Error('Googleカレンダーへのイベント追加に失敗しました');
      }

      const eventData = await response.json();
      const docRef = await addDoc(collection(firestore, 'reservations'), {
        startDate: startDateTime,
        endDate: endDateTime,
        userId,
        description: userName,
        studioId: currentUser?.studioId,
        visited: false,
        active: true,
        changed: false,
        type: temporary ? 'temporary' : ticketToUse.data().type,
        ticketId: temporary ? null : ticketToUse.id,
        eventId: eventData.id,
        priority: false,
        instructorId: selectedInstructor || availableInstructors[0],
      });

      reservationId = docRef.id;

      const embeds = [
        {
          title: 'ご予約がありました🗓️' + (temporary ? '（確保）' : ''),
          description: `${
            currentUser.instructor
              ? currentUser.studio.instructors[currentUser.uid] || '管理者'
              : userName
          }がアプリから操作して追加しました`,
          color: temporary ? 16738740 : 8900331,
          fields: [
            {
              name: '日時',
              value: `${startDateTime
                .replace('-', '年')
                .replace('-', '月')
                .replace('T', '日')}`,
              inline: true,
            },
            {
              name: '予約者',
              value: userName,
              inline: true,
            },
          ],
          footer: { text: 'ご予約承認時刻' },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(
        'reserve',
        embeds,
        currentUser?.studio.instructors[
          selectedInstructor || availableInstructors[0]
        ]
      );
      await sendDiscordNotification('audit', embeds);

      setReserving(false);
      navigate('/home', { state: { reload: true } });
    } catch (error) {
      console.error('予約の追加に失敗しました', error);

      // チケットの`used`フラグを`false`に戻す
      if (reservationId) {
        await updateDoc(doc(firestore, 'reservations', reservationId), {
          active: false,
        });
      }
      const userDocRef = doc(firestore, 'users', userId);
      const ticketsRef = collection(userDocRef, 'tickets');
      const ticketQuery = query(
        ticketsRef,
        where('used', '==', true),
        where(
          'expire',
          '>=',
          new Date(new Date().setDate(new Date().getDate() + 1))
        )
      );
      const ticketSnapshot = await getDocs(ticketQuery);
      const ticketToUse = ticketSnapshot.docs[0];

      await updateDoc(ticketToUse.ref, { used: false });

      setReserving(false);
      alert(
        'こちらは現時点でアプリ上では◯が付いていますが、インストラクターが研修などで対応できない時間のようです。別の候補にてご予約ください🙇‍♀️'
      );
      onClose();
    }
  };

  const handlePrevDays = () => {
    setDateOffset(prev => Math.max(prev - 3, 1)); // 明日以降を表示するために最小値を1に設定
  };

  const handleNextDays = () => {
    setDateOffset(prev => Math.min(prev + 3, 42)); // 最大45日先まで
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDays(),
    onSwipedRight: () => handlePrevDays(),
  });

  const handleSearchChange = e => {
    const searchValue = e.target.value.toLowerCase();
    setFilteredUsers(
      availableUsers.filter(user =>
        user.fullName.toLowerCase().includes(searchValue)
      )
    );
  };

  const handleUserSelect = e => {
    const selectedUserId = e.target.value;
    setSelectedUser(selectedUserId);
    const user = availableUsers.find(user => user.id === selectedUserId);
    setSelectedUserName(user?.fullName || '');
    if (user?.validTicketCount === 0) {
      setTemporary(true);
    }
    console.log('選択されたユーザー:', user);
  };

  if (fetching)
    return <LoadingModal text="予約が可能な時間を問い合わせ中..." />;
  if (fetching2) return <LoadingModal text="会員の一覧を問い合わせ中..." />;
  if (reserving) return <LoadingModal text="ご予約を作成中..." />;

  const instructorIds = Object.keys(currentUser.studio.instructors);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      {...handlers}
    >
      <Logo text="ご予約ページ" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {currentUser.instructor && (
          <Box mb={4}>
            <Text fontSize="sm" mb={2} fontWeight="bold">
              代わりに予約をとる会員を選択：
            </Text>
            <Input
              placeholder="入力で選択候補を絞れます"
              mb={2}
              onChange={handleSearchChange}
            />
            <Select
              placeholder="会員氏名を選択してください"
              onChange={handleUserSelect}
            >
              {filteredUsers.map(user => (
                <option key={user.id} value={user.id}>
                  {user.fullName}（{user.validTicketCount} 枚）
                </option>
              ))}
            </Select>
          </Box>
        )}
        <Text fontSize="sm" mb={2} fontWeight="bold">
          　{currentUser?.announce}
        </Text>

        <Tabs defaultIndex={0}>
          <TabList>
            <Tab onClick={() => setIsAllInstructorsTab(true)}>全員</Tab>
            {instructorIds.map(instructorId => (
              <Tab
                key={instructorId}
                onClick={() => setIsAllInstructorsTab(false)}
              >
                {currentUser.studio.instructors[instructorId]}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <HStack justifyContent="space-between" mb={2}>
                <Button
                  size="sm"
                  onClick={handlePrevDays}
                  disabled={dateOffset === 1} // 明日以降を表示するためにボタンを無効にする条件を変更
                  colorScheme={dateOffset === 1 ? 'gray' : 'teal'}
                >
                  前へ
                </Button>
                <Text fontWeight="bold">{`${days[0].getMonth() + 1}月`}</Text>
                <Button
                  size="sm"
                  onClick={handleNextDays}
                  disabled={dateOffset >= 42}
                  colorScheme={dateOffset >= 42 ? 'gray' : 'teal'}
                >
                  次へ
                </Button>
              </HStack>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th style={{ textAlign: 'center' }}>時間</Th>
                    {days.map(day => (
                      <Th
                        style={{
                          textAlign: 'center',
                          marginBottom: '4px',
                          color:
                            day.getDay() === 0
                              ? 'red'
                              : day.getDay() === 6
                              ? 'blue'
                              : 'black',
                        }}
                        key={day.toDateString()}
                      >
                        {`${day.getDate()}日 (${
                          ['日', '月', '火', '水', '木', '金', '土'][
                            day.getDay()
                          ]
                        })`}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {hours.map(hour => (
                    <Tr key={hour}>
                      <Td style={{ textAlign: 'center' }}>{`${hour}:00`}</Td>
                      {days.map(day => (
                        <Td
                          key={`${day.toDateString()}-${hour}`}
                          onClick={() => handleReserveClick(day, hour, null)}
                          style={{
                            cursor:
                              instructorIds.some(
                                instructorId =>
                                  isShiftAvailable(day, hour, instructorId) &&
                                  !isHourReserved(day, hour)
                              ) && !isFixedSlot(day, hour)
                                ? 'pointer'
                                : 'default',
                            textAlign: 'center',
                            backgroundColor:
                              instructorIds.some(
                                instructorId =>
                                  isShiftAvailable(day, hour, instructorId) &&
                                  !isHourReserved(day, hour)
                              ) && !isFixedSlot(day, hour)
                                ? '#eafffa'
                                : 'silver',
                          }}
                        >
                          {instructorIds.some(
                            instructorId =>
                              isShiftAvailable(day, hour, instructorId) &&
                              !isHourReserved(day, hour)
                          ) && !isFixedSlot(day, hour)
                            ? '◯'
                            : 'ー'}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
            {instructorIds.map(instructorId => (
              <TabPanel key={instructorId}>
                <HStack justifyContent="space-between" mb={2}>
                  <Button
                    size="sm"
                    onClick={handlePrevDays}
                    disabled={dateOffset === 1} // 明日以降を表示するためにボタンを無効にする条件を変更
                    colorScheme={dateOffset === 1 ? 'gray' : 'teal'}
                  >
                    前へ
                  </Button>
                  <Text fontWeight="bold">{`${days[0].getMonth() + 1}月`}</Text>
                  <Button
                    size="sm"
                    onClick={handleNextDays}
                    disabled={dateOffset >= 42}
                    colorScheme={dateOffset >= 42 ? 'gray' : 'teal'}
                  >
                    次へ
                  </Button>
                </HStack>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th style={{ textAlign: 'center' }}>時間</Th>
                      {days.map(day => (
                        <Th
                          style={{
                            textAlign: 'center',
                            marginBottom: '4px',
                            color:
                              day.getDay() === 0
                                ? 'red'
                                : day.getDay() === 6
                                ? 'blue'
                                : 'black',
                          }}
                          key={day.toDateString()}
                        >
                          {`${day.getDate()}日 (${
                            ['日', '月', '火', '水', '木', '金', '土'][
                              day.getDay()
                            ]
                          })`}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {hours.map(hour => (
                      <Tr key={hour}>
                        <Td style={{ textAlign: 'center' }}>{`${hour}:00`}</Td>
                        {days.map(day => (
                          <Td
                            key={`${day.toDateString()}-${hour}`}
                            onClick={() =>
                              handleReserveClick(day, hour, instructorId)
                            }
                            style={{
                              cursor:
                                isShiftAvailable(day, hour, instructorId) &&
                                !isHourReserved(day, hour) &&
                                !isFixedSlot(day, hour)
                                  ? 'pointer'
                                  : 'default',
                              textAlign: 'center',
                              backgroundColor:
                                isShiftAvailable(day, hour, instructorId) &&
                                !isHourReserved(day, hour) &&
                                !isFixedSlot(day, hour)
                                  ? '#eafffa'
                                  : 'silver',
                            }}
                          >
                            {isShiftAvailable(day, hour, instructorId) &&
                            !isHourReserved(day, hour) &&
                            !isFixedSlot(day, hour)
                              ? '◯'
                              : 'ー'}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
        予約の追加が完了するとホームに移動します🙆‍♀️
      </Text>
      <BackButton />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>ご予約の確認{temporary ? '（確保）' : ''}</ModalHeader>
          <ModalBody>
            {isAllInstructorsTab ? (
              <>
                {selectedUserName || currentUser.fullName} さん
                <br />
                {selectedDateTime && (
                  <>
                    {formatDateTime(
                      selectedDateTime.date,
                      selectedDateTime.hour
                    )}{' '}
                    を選択中✅
                    <br />
                    <br />
                  </>
                )}
                担当は次のインストラクターです🙆‍♀️
                <RadioGroup
                  onChange={setSelectedInstructor}
                  defaultValue={availableInstructors[0]}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <VStack>
                    {availableInstructors.map(instructor => (
                      <Radio key={instructor} value={instructor} my={2}>
                        {currentUser.studio.instructors[instructor]}
                      </Radio>
                    ))}
                  </VStack>
                </RadioGroup>
              </>
            ) : (
              selectedDateTime && (
                <>
                  {formatDateTime(selectedDateTime.date, selectedDateTime.hour)}{' '}
                  を選択中✅
                  <br />
                  <br />
                  担当は{
                    currentUser.studio.instructors[selectedInstructor]
                  }{' '}
                  インストラクターです🙌
                </>
              )
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" mr={2} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={handleConfirmReservation}
              disabled={
                (isAllInstructorsTab && !selectedInstructor) ||
                (currentUser.instructor && !selectedUser)
              }
            >
              予約を作成
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isNoTicketsOpen}
        onClose={() => {
          onNoTicketsClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>時間枠の確保</ModalHeader>
          <ModalBody>
            チケットがありませんが、予約を一時的に確保しますか？
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onNoTicketsClose();
              }}
            >
              キャンセル
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                handleConfirmReservation();
              }}
            >
              確保
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default AddReservation;
