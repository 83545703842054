import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Container,
  Text,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  IconButton,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from './LoadingModal';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
} from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { sendDiscordNotification } from '../utils/discord';

const formatDateToISOStringLocal = date => {
  const pad = num => (num < 10 ? '0' + num : num);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // 月は0から始まるため、+1する
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

const ReservationsList = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState([]);
  const [reserving, setReserving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(null);

  const handleSubmit = useCallback(async () => {
    if (ticketNumber === 0) {
      alert('チケットがありません。');
      onClose();
      return;
    }
    setReserving(true);
    if (!selectedReservation || selectedReservation === '') {
      alert('予約候補を選択してください');
      setReserving(false);
      return;
    }
    const startDateTime = selectedReservation.startDate;
    const endDateTime = selectedReservation.endDate;
    console.log(startDateTime);
    console.log(endDateTime);

    const reservationsRef = collection(firestore, 'reservations');

    const q = query(
      reservationsRef,
      where('startDate', '<=', endDateTime),
      where('active', '==', true)
    );
    const querySnapshot = await getDocs(q);
    const conflicts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    const isConflict = conflicts.some(reservation => {
      if (
        startDateTime < reservation.endDate &&
        endDateTime > reservation.startDate
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (isConflict) {
      alert(
        '選択された日時には専用枠を設定される前に予約が入っていた可能性が高いです。この候補以外か、専用枠外での予約をお願いします。'
      );
      setReserving(false);
      onClose();
      return;
    } else {
      try {
        const now = new Date();
        const nowStr = now.toISOString();
        const calendarEvent = {
          calendarId: `${currentUser?.studio?.slug}@studio-medy.com`,
          event: {
            summary:
              '【優先】' +
              currentUser?.fullName +
              '（' +
              currentUser.studio.instructors[selectedReservation.instructorId] +
              '）',
            location: currentUser?.studio?.name,
            description: `ご本人がアプリから追加（${nowStr}）`,
            colorId: 7,
            start: {
              dateTime: `${startDateTime}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
            end: {
              dateTime: `${endDateTime}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
          },
        };

        const response = await fetch(
          'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
          {
            method: 'POST',
            body: JSON.stringify(calendarEvent),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          console.error(
            'Googleカレンダーへのイベント追加に失敗しました',
            response
          );
          throw new Error('Googleカレンダーへのイベント追加に失敗しました');
        }

        const eventData = await response.json();
        const docRef = await addDoc(collection(firestore, 'reservations'), {
          startDate: startDateTime,
          endDate: endDateTime,
          userId: currentUser?.uid,
          description: currentUser?.fullName,
          studioId: currentUser?.studioId,
          visited: false,
          active: true,
          changed: false,
          type: 'once',
          ticketId: currentUser.tickets[0].id,
          eventId: eventData.id,
          priority: true,
          instructorId: selectedReservation.instructorId,
        });

        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const ticketRef = doc(userDocRef, 'tickets', currentUser.tickets[0].id);
        await updateDoc(ticketRef, {
          used: true,
        });

        const embeds = [
          {
            title: 'ご予約がありました🗓️（専用枠）',
            description: `${currentUser?.fullName}さんが新しく予約しました。`,
            color: 8900331,
            fields: [
              {
                name: '日時',
                value: `${startDateTime
                  .replace('-', '年')
                  .replace('-', '月')
                  .replace('T', '日')}`,
                inline: true,
              },
              {
                name: '予約者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: 'ご予約承認時刻',
            },
            timestamp: new Date().toISOString(),
          },
        ];
        console.log('予約の追加に成功しました', docRef.id);

        await sendDiscordNotification('reserve', embeds);
        setReserving(false);
        navigate(`/home`, { state: { reload: true } });
      } catch (error) {
        setReserving(false);
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const ticketRef = doc(userDocRef, 'tickets', currentUser.tickets[0].id);
        await updateDoc(ticketRef, {
          used: false,
        });

        const reservationDoc = query(
          reservationsRef,
          where('startDate', '==', startDateTime),
          where('userId', '==', currentUser?.uid)
        );
        const reservationSnapshot = await getDocs(reservationDoc);
        reservationSnapshot.forEach(async res => {
          const resRef = doc(firestore, 'reservations', res.id);
          await updateDoc(resRef, {
            active: false,
          });
        });

        alert(
          '専用枠の設定前に他の方が予約していた時間か、インストラクターが研修などで対応できない時間のようです。別の候補か、専用枠外でご予約ください。'
        );
        navigate(`/home`, { state: { reload: true } });
        console.error('予約の追加に失敗しました', error);
      }
    }
  }, [
    ticketNumber,
    selectedReservation,
    onClose,
    currentUser.studio?.slug,
    currentUser.studio.instructors,
    currentUser.studio?.name,
    currentUser?.fullName,
    currentUser.tickets,
    currentUser.uid,
    currentUser?.studioId,
    navigate,
  ]);

  useEffect(() => {
    const fixies = currentUser.fixies;
    const weekdayMap = { 日: 0, 月: 1, 火: 2, 水: 3, 木: 4, 金: 5, 土: 6 };

    let targets = [];

    fixies.forEach(fix => {
      const today = new Date();
      let count = 0;

      while (count < 4) {
        const todayDay = today.getDay();
        const fixDay = weekdayMap[fix.weekday];
        let diff = fixDay - todayDay;
        if (diff < 0) {
          diff += 7;
        } else if (diff === 0 && today.getHours() >= fix.start) {
          diff += 7;
        }

        const startDate = new Date(today);
        startDate.setDate(today.getDate() + diff);
        startDate.setHours(fix.start, 0, 0, 0);

        const endDate = new Date(today);
        endDate.setDate(today.getDate() + diff);
        endDate.setHours(fix.start + 1, 0, 0, 0);

        const isoStartDateString = formatDateToISOStringLocal(startDate);
        const isoEndDateString = formatDateToISOStringLocal(endDate);
        const formattedStartDateString = isoStartDateString.substring(0, 16);
        const formattedEndDateString = isoEndDateString.substring(0, 16);

        const instructorId =
          currentUser.studio.shifts[`${fix.weekday}${fix.start}`] || [];

        targets.push({
          startDate: formattedStartDateString,
          endDate: formattedEndDateString,
          instructorId: instructorId[0],
        });

        today.setDate(today.getDate() + 7);
        count++;
      }
    });
    targets.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    console.log(
      targets.slice(
        0,
        currentUser?.tickets?.length * currentUser?.fixies?.length || 0
      )
    );
    setReservations(targets);
    setTicketNumber(currentUser?.tickets?.length);
  }, [currentUser]);

  if (reserving) return <LoadingModal text="専用枠でご予約を受付中..." />;

  return (
    <>
      <Container
        backgroundColor={'#ede9e5'}
        borderRadius={'10'}
        mt={4}
        pt={1}
        t
      >
        <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          mt={6}
          p={4}
          w="100%"
          backgroundColor={'#fdfdfd'}
          boxShadow="sm"
        >
          <Text textAlign={'center'} fontSize="lg" mb="4">
            <b>{currentUser?.fullName}さまご予約専用枠</b>
          </Text>
          <Text textAlign={'center'} fontSize="sm" mb="4">
            以下の日時からご予約をお願いします。
          </Text>
          <Text textAlign={'center'} fontSize="xs" mb="4">
            チケット残数：{ticketNumber}枚
          </Text>
          {reservations.length > 0 ? (
            reservations.map(reservation => (
              <Flex
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                p={3}
                mt={2}
                justifyContent="space-between"
                alignItems="center"
                key={reservation.startDate}
              >
                <Text fontSize="lg">
                  {reservation.startDate
                    .replace('-', '/')
                    .replace('-', '/')
                    .replace('T', ' ')}
                  （{currentUser.studio.instructors[reservation.instructorId]}）
                </Text>
                {currentUser?.reservations?.findIndex(
                  res => res.startDate === reservation.startDate
                ) === -1 ? (
                  <IconButton
                    aria-label="予約を確定"
                    icon={<FontAwesomeIcon icon={faAdd} />}
                    size="sm"
                    variant={'stylishBlack'}
                    onClick={e => {
                      e.stopPropagation();
                      console.log(reservation);
                      setSelectedReservation(reservation);
                      onOpen();
                    }}
                  />
                ) : (
                  <Text fontSize="sm" color="gray">
                    予約済み
                  </Text>
                )}
              </Flex>
            ))
          ) : (
            <Text textAlign="center">優先ご予約候補はありません。</Text>
          )}
          <Text textAlign={'left'} p={4} color={'gray'} fontSize="sm" mt="4">
            {currentUser?.fullName}
            さまが専用枠を設定する前に他の方のレッスン予約が入っていた場合などに、
            <b>専用枠でもご予約ができない</b>ことがあります。
          </Text>
        </Box>
        <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
          予約完了後にホームに移動します🙆‍♀️
        </Text>
        <BackButton />
        <Text fontSize="xs" color="teal" textAlign={'center'} mb={4}>
          別の専用枠を設定する場合は以下からどうぞ
        </Text>
        <Flex justifyContent={'center'}>
          <Button
            size="sm"
            colorScheme="teal"
            onClick={() => navigate(`/set-fixies`)}
            alignSelf={'center'}
          >
            専用枠を変更する
          </Button>
        </Flex>
        <br />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxWidth={'x2'} mx={8}>
            <ModalHeader>優先ご予約の確認</ModalHeader>
            <ModalBody>専用枠でこの日時のご予約を作成します</ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                colorScheme="telegram"
                mr={2}
                onClick={() => handleSubmit(selectedReservation)}
              >
                ご予約の確定
              </Button>
              <Button size="sm" variant="ghost" onClick={onClose}>
                閉じる
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
      {'　'}
    </>
  );
};

export default ReservationsList;
