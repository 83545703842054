import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Text,
  Container,
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  Select,
  Input,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from '../components/LoadingModal';
import Logo from './Logo';
import {
  firestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from '../firebaseConfig';
import { sendDiscordNotification } from '../utils/discord';
import { useSwipeable } from 'react-swipeable';

const AddBlockReservation = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reserving, setReserving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [selectedDateTimes, setSelectedDateTimes] = useState([]);
  const [dateOffset, setDateOffset] = useState(0);
  const [purpose, setPurpose] = useState('');
  const [description, setDescription] = useState('');
  const [selectedInstructor, setSelectedInstructor] = useState('');

  const menu = {
    block: '予約受付のブロック',
    temporary: '来店日時の仮押さえ',
    training: 'レッスン関係の研修',
    outsource: 'レッスン以外の業務',
    meeting: '会議・打ち合わせ等',
    trial: '体験レッスンの予約',
  };
  const summary = {
    block: '【空室】',
    temporary: '【確保】',
    training: '【研修】',
    outsource: '【作業】',
    meeting: '【会議】',
    trial: '【体験】',
  };
  const calendarColors = {
    block: { google: 8, discord: 7763574 },
    temporary: { google: 4, discord: 16738740 },
    training: { google: 2, discord: 2067276 },
    outsource: { google: 9, discord: 9055202 },
    meeting: { google: 5, discord: 16776960 },
    trial: { google: 11, discord: 15158332 },
    default: { google: 7, discord: 8900331 },
  };

  const openGoogleCalendarApp = () => {
    window.location.href =
      'https://calendar.google.com/calendar/render?cid=hatagaya@studio-medy.com';
  };

  const getNextDays = offset => {
    const days = [];
    const today = new Date();
    today.setDate(today.getDate() + offset);
    for (let i = 0; i < 7; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const combineDateTime = (date, hour) => {
    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
    return `${dateStr}T${String(hour).padStart(2, '0')}:00`;
  };

  const isFixedSlot = (date, hour) => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
    return currentUser.studio.fixies.some(
      fixie => fixie.weekday === weekday && fixie.start === hour
    );
  };

  const days = getNextDays(dateOffset);
  const hours = Array.from({ length: 23 - 9 + 1 }, (_, i) => i + 9);

  useEffect(() => {
    const fetchReservations = async () => {
      setFetching(true);
      const reservationsRef = collection(firestore, 'reservations');
      const q = query(
        reservationsRef,
        where('studioId', '==', currentUser?.studioId),
        where('active', '==', true),
        where('startDate', '>=', new Date().toISOString())
      );
      const querySnapshot = await getDocs(q);
      const reservations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReservations(reservations);
      setFetching(false);
    };
    fetchReservations();
  }, [currentUser?.studioId]);

  const isHourReserved = (date, hour) => {
    return reservations.some(reservation => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      return (
        reservationStart.toDateString() === date.toDateString() &&
        hour >= reservationStart.getHours() &&
        hour < reservationEnd.getHours()
      );
    });
  };

  const isShiftAvailable = (date, hour, instructorId) => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
    const shiftKey = `${weekday}${hour}`;
    return (
      currentUser.studio.shifts[shiftKey] &&
      currentUser.studio.shifts[shiftKey].includes(instructorId)
    );
  };

  const formatDateTime = (date, hour) => {
    const day = new Date(date);
    const formattedDate = `${day.getFullYear()}年${
      day.getMonth() + 1
    }月${day.getDate()}日`;
    return `${formattedDate} ${hour}:00`;
  };

  const handleReserveClick = (date, hour) => {
    if (isFixedSlot(date, hour)) {
      return;
    }
    const selected = selectedDateTimes.find(
      dt => dt.date.toDateString() === date.toDateString() && dt.hour === hour
    );

    if (selected) {
      setSelectedDateTimes(prev =>
        prev.filter(
          dt =>
            !(
              dt.date.toDateString() === date.toDateString() && dt.hour === hour
            )
        )
      );
    } else {
      if (isHourReserved(date, hour)) {
        alert('この時間帯は他の人の予約枠です。');
      } else {
        setSelectedDateTimes(prev => [...prev, { date: new Date(date), hour }]);
      }
    }
  };

  const handleConfirmReservation = async () => {
    setReserving(true);

    const groupedDateTimes = [];
    let tempGroup = [];

    const sortedDateTimes = [...selectedDateTimes].sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return a.hour - b.hour;
    });

    sortedDateTimes.forEach((current, index) => {
      const next = sortedDateTimes[index + 1];
      tempGroup.push(current);

      if (
        !next ||
        next.date.toDateString() !== current.date.toDateString() ||
        next.hour !== current.hour + 1
      ) {
        groupedDateTimes.push([...tempGroup]);
        tempGroup = [];
      }
    });

    const promises = groupedDateTimes.map(async group => {
      const startDateTime = combineDateTime(group[0].date, group[0].hour);
      const endDateTime = combineDateTime(
        group[group.length - 1].date,
        group[group.length - 1].hour + 1
      );

      const now = new Date();
      const nowStr = now.toISOString();
      const reservationColor =
        calendarColors[purpose] || calendarColors.default;
      const calendarEvent = {
        calendarId: `${currentUser?.studio?.slug}@studio-medy.com`,
        event: {
          summary: summary[purpose] + '' + description + '',
          location: currentUser?.studio?.name,
          description: `${currentUser?.fullName}が管理画面から追加（${nowStr}）`,
          colorId: reservationColor.google,
          start: {
            dateTime: `${startDateTime}:00+09:00`,
            timeZone: 'Asia/Tokyo',
          },
          end: {
            dateTime: `${endDateTime}:00+09:00`,
            timeZone: 'Asia/Tokyo',
          },
        },
      };

      const response = await fetch(
        'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
        {
          method: 'POST',
          body: JSON.stringify(calendarEvent),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        window.alert('カレンダーに他の予定があります。');
        throw new Error('Googleカレンダーへのイベント追加に失敗しました');
      }

      const eventData = await response.json();
      await addDoc(collection(firestore, 'reservations'), {
        startDate: startDateTime,
        endDate: endDateTime,
        userId: currentUser?.uid,
        studioId: currentUser?.studioId,
        ticketId: null,
        visited: false,
        active: true,
        changed: false,
        type: purpose,
        eventId: eventData.id,
        priority: false,
        description,
        instructorId: selectedInstructor, // 追加：選択されたインストラクターIDを保存
      });

      const embeds = [
        {
          title: 'スケジュールが追加されました🗓️',
          description: `${currentUser?.fullName}さんが以下の予定を追加しました。`,
          color: reservationColor.discord,
          fields: [
            {
              name: '予定の種類',
              value: menu[purpose],
              inline: true,
            },
            {
              name: '予定の内容',
              value: description,
              inline: true,
            },
            {
              name: '開始時刻',
              value: `${startDateTime.replace('T', ' ')}`,
              inline: true,
            },
            {
              name: '終了時刻',
              value: `${endDateTime.replace('T', ' ')}`,
              inline: true,
            },
          ],
          footer: { text: '予定追加の承認時刻' },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(
        'reserve',
        embeds,
        currentUser?.studio.instructors[selectedInstructor]
      );
      await sendDiscordNotification('audit', embeds);
    });

    try {
      await Promise.all(promises);
      setReserving(false);
      window.location.reload();
    } catch (error) {
      console.error('予約の追加に失敗しました', error);
      setReserving(false);
    }
  };

  const handlePrevDays = () => {
    setDateOffset(prev => Math.max(prev - 7, 0));
  };

  const handleNextDays = () => {
    setDateOffset(prev => Math.min(prev + 7, 87)); // 最大90日先まで
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDays(),
    onSwipedRight: () => handlePrevDays(),
  });

  if (fetching)
    return <LoadingModal text="予約が可能な時間を問い合わせ中..." />;
  if (reserving) return <LoadingModal text="ご予約を作成中..." />;

  const instructorIds = Object.keys(currentUser.studio.instructors);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="スケジュール管理ページ" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        {...handlers}
      >
        <HStack justifyContent="space-between" mb={2}>
          <Button
            size="sm"
            onClick={handlePrevDays}
            disabled={dateOffset === 0}
            colorScheme={dateOffset === 0 ? 'gray' : 'teal'}
          >
            前へ
          </Button>
          <Text fontWeight="bold">{`${days[0].getMonth() + 1}月`}</Text>
          <Button
            size="sm"
            onClick={handleNextDays}
            disabled={dateOffset >= 42}
            colorScheme={dateOffset >= 42 ? 'gray' : 'teal'}
          >
            次へ
          </Button>
        </HStack>
        <Table variant="simple" size="xs">
          <Thead>
            <Tr>
              <Th style={{ textAlign: 'center', fontSize: 12 }}>時間</Th>
              {days.map(day => (
                <Th
                  style={{
                    textAlign: 'center',
                    marginBottom: '4px',
                    fontSize: 10,
                    color:
                      day.getDay() === 0
                        ? 'red'
                        : day.getDay() === 6
                        ? 'blue'
                        : 'black',
                  }}
                  key={day.toDateString()}
                >
                  {`${day.getDate()}日 (${
                    ['日', '月', '火', '水', '木', '金', '土'][day.getDay()]
                  })`}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {hours.map(hour => (
              <Tr key={hour}>
                <Td
                  style={{ textAlign: 'center', fontSize: 12 }}
                >{`${hour}:00　`}</Td>
                {days.map(day => (
                  <Td
                    key={`${day.toDateString()}-${hour}`}
                    onClick={() => handleReserveClick(day, hour)}
                    style={{
                      fontSize: 12,
                      cursor:
                        !isFixedSlot(day, hour) &&
                        instructorIds.some(
                          instructorId =>
                            isShiftAvailable(day, hour, instructorId) &&
                            !isHourReserved(day, hour)
                        )
                          ? 'pointer'
                          : 'default',
                      textAlign: 'center',
                      backgroundColor: selectedDateTimes.some(
                        dt =>
                          dt.date.toDateString() === day.toDateString() &&
                          dt.hour === hour
                      )
                        ? '#afc7fb'
                        : !isFixedSlot(day, hour) &&
                          instructorIds.some(
                            instructorId =>
                              isShiftAvailable(day, hour, instructorId) &&
                              !isHourReserved(day, hour)
                          )
                        ? '#eafffa'
                        : 'silver',
                    }}
                  >
                    {isHourReserved(day, hour)
                      ? '×'
                      : isFixedSlot(day, hour)
                      ? '専'
                      : instructorIds.some(instructorId =>
                          isShiftAvailable(day, hour, instructorId)
                        )
                      ? '◯'
                      : 'ー'}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <VStack spacing={2} mt={4} align="center">
        {selectedDateTimes.map(({ date, hour }, index) => (
          <Text key={index}>{formatDateTime(date, hour)}</Text>
        ))}
        {selectedDateTimes.length === 0 ? (
          <Text fontSize="12" color="red">
            {purpose === 'block' &&
            selectedInstructor === 'xYDCd2kiz9WUqtFMQMYwPsnDzWL2'
              ? 'クレーム防止のためブロックしすぎないようご注意下さい'
              : '予定を追加する時間を選択してください'}
          </Text>
        ) : null}
        <Select
          placeholder="誰の予定ですか？"
          value={selectedInstructor}
          onChange={e => setSelectedInstructor(e.target.value)}
          backgroundColor={'#fdfdfd'}
        >
          {instructorIds.map(instructorId => (
            <option key={instructorId} value={instructorId}>
              {currentUser.studio.instructors[instructorId]}
            </option>
          ))}
        </Select>
        <Select
          placeholder="予定の内容は何ですか？"
          value={purpose}
          onChange={e => setPurpose(e.target.value)}
          backgroundColor={'#fdfdfd'}
        >
          {Object.entries(menu).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>
        <Input
          placeholder="お客さんの名前や具体的な内容のメモ"
          value={description}
          onChange={e => setDescription(e.target.value)}
          backgroundColor={'#fdfdfd'}
        />
        <Button
          colorScheme="blue"
          my={2}
          onClick={handleConfirmReservation}
          isDisabled={
            selectedDateTimes.length === 0 || !purpose || !selectedInstructor
          }
        >
          予定を追加する
        </Button>
        <Text color={'gray'} fontSize="xs" mb={2} textAlign={'center'}>
          予定を追加すると自動で画面が更新されます🙆‍♀️
        </Text>
        <Button
          onClick={() =>
            navigate(
              `/studios/${currentUser.studioId}/users/${currentUser.uid}/reservations`
            )
          }
          variant={'outlineBlack'}
          mb={2}
        >
          予定一覧を見る
        </Button>
        <Button
          onClick={openGoogleCalendarApp}
          colorScheme="teal"
          mb={4}
          size="sm"
        >
          Googleカレンダーを開く
        </Button>
        <BackButton />
      </VStack>
    </Container>
  );
};

export default AddBlockReservation;
