import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Container,
  Text,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Input,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from './LoadingModal';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { sendDiscordNotification } from '../utils/discord';
import dayjs from 'dayjs';

const ReservationsList = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } =
    location.state && location.state.user
      ? location.state
      : { user: currentUser };
  const { studioId, userId } = useParams();
  const [reservations, setReservations] = useState([]);
  const [canceling, setCanceling] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [filter, setFilter] = useState('all');
  const [newInstructor, setNewInstructor] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState(''); // 新しいエンドデートの状態

  const summary = {
    block: '【空室】',
    temporary: '【確保】',
    training: '【研修】',
    outsource: '【作業】',
    meeting: '【会議】',
    trial: '【体験】',
    plan: '【予約】',
    once: '【予約】',
  };

  const calendarColors = {
    block: { google: 8, discord: 7763574 },
    temporary: { google: 4, discord: 16738740 },
    training: { google: 2, discord: 2067276 },
    outsource: { google: 9, discord: 9055202 },
    meeting: { google: 5, discord: 16776960 },
    trial: { google: 11, discord: 15158332 },
    default: { google: 7, discord: 8900331 },
  };

  const onClickBlock = () =>
    navigate(`/studios/${studioId}/instructors/${currentUser.uid}/block`);

  const isPastCancellationDeadline = reservationDate => {
    let temp = reservationDate;
    if (reservationDate.match(/T\d:\d/)) {
      temp = reservationDate.replace(/T(\d):(\d)/, 'T0$1:$2');
    }

    const reservationDateTime = new Date(temp);
    const now = new Date();
    const deadline = new Date(reservationDateTime);
    deadline.setDate(reservationDateTime.getDate() - 1);
    deadline.setHours(21, 0, 0, 0);
    return now > deadline;
  };

  const cancelReservation = async reservation => {
    setCanceling(true);
    if (reservation.ticketId) {
      const userDocRef = doc(firestore, 'users', reservation.userId);
      const ticketRef = doc(userDocRef, 'tickets', reservation.ticketId);
      const embeds = [
        {
          title: 'チケット返却通知',
          description: `以下のチケットが返却されました。`,
          color: 16752762,

          fields: [
            {
              name: 'ユーザーID',
              value: `${reservation.userId}`,
              inline: true,
            },
            {
              name: 'ユーザー名',
              value: `${reservation.description}`,
              inline: true,
            },
            {
              name: 'チケットID',
              value: reservation.ticketId,
              inline: true,
            },
          ],
          footer: {
            text: 'チケット返却時刻',
          },
          timestamp: new Date().toISOString(),
        },
      ];
      await sendDiscordNotification('audit', embeds);
      await updateDoc(ticketRef, {
        used: false,
      }).catch(error => {
        console.error('チケットの使用済みフラグの更新に失敗しました: ', error);
        const embeds = [
          {
            title: 'キャンセルエラー',
            description: `以下の予定がキャンセルされる時にエラーが出ました`,
            color: 16752762,
            fields: [
              {
                name: '日時',
                value: `${reservation.startDate
                  .replace('-', '年')
                  .replace('-', '月')
                  .replace('T', '日')}`,
                inline: true,
              },
              {
                name: '予約者',
                value: currentUser?.fullName,
                inline: true,
              },
              {
                name: 'エラー',
                value: error.text,
                inline: true,
              },
            ],
            footer: {
              text: 'キャンセル承認時刻',
            },
            timestamp: new Date().toISOString(),
          },
        ];
        sendDiscordNotification('audit', embeds);
      });
    }
    const reservationRef = doc(firestore, 'reservations', reservation.id);
    await updateDoc(reservationRef, {
      active: false,
    })
      .then(async () => {
        setReservations(reservations.filter(r => r.id !== reservation.id));
        if (reservation.eventId) {
          const response = await fetch(
            'https://asia-northeast1-medy-system.cloudfunctions.net/delete_google_calendar_event',
            {
              method: 'POST',
              body: JSON.stringify({
                calendarId: `${user.studio.slug}@studio-medy.com`,
                eventId: reservation.eventId,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!response.ok) {
            console.error(
              'Googleカレンダーへのイベント削除に失敗しました',
              response
            );
            return;
          }
        }
        const reservationColor =
          calendarColors[reservation.type] || calendarColors.default;
        const embeds = [
          {
            title: 'キャンセル通知',
            description: `以下の予定がキャンセルされました。`,
            color: reservationColor.discord,
            fields: [
              {
                name: '日時',
                value: `${reservation.startDate
                  .replace('-', '年')
                  .replace('-', '月')
                  .replace('T', '日')}`,
                inline: true,
              },
              {
                name: '予定',
                value: reservation.description,
                inline: true,
              },
            ],
            footer: {
              text: 'キャンセル承認時刻',
            },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification(
          'hatagaya',
          embeds,
          currentUser.studio.instructors[reservation.instructorId]
        );

        await sendDiscordNotification('reserve', embeds);
        await sendDiscordNotification('audit', embeds);

        setCanceling(false);
        onClose();
        navigate('/home', { state: { reload: true } });
      })
      .catch(error => {
        console.error('キャンセルに失敗しました: ', error);
        setCanceling(false);
      });
  };

  const updateReservationDetails = async reservation => {
    const reservationRef = doc(firestore, 'reservations', reservation.id);

    await updateDoc(reservationRef, {
      instructorId: newInstructor,
      startDate: newStartDate,
      endDate: newEndDate,
    })
      .then(async () => {
        setUpdating(true);
        const updatedReservations = reservations.map(r =>
          r.id === reservation.id
            ? {
                ...r,
                instructorId: newInstructor,
                startDate: newStartDate,
                endDate: newEndDate,
              }
            : r
        );
        setReservations(updatedReservations);

        const reservationColor =
          calendarColors[reservation.type] || calendarColors.default;

        // Googleカレンダーイベントの更新
        const calendarEvent = {
          calendarId: `${currentUser?.studio?.slug}@studio-medy.com`,
          eventId: reservation.eventId,
          event: {
            summary: `${summary[reservation.type]}${reservation.description}（${
              currentUser.studio.instructors[newInstructor]
            }）`,
            location: currentUser?.studio?.name,
            description: `インストラクターが管理画面から変更（${new Date().toISOString()}）`,
            colorId: reservationColor.google,
            start: {
              dateTime: `${newStartDate}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
            end: {
              dateTime: `${newEndDate}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
          },
        };

        const response = await fetch(
          'https://asia-northeast1-medy-system.cloudfunctions.net/update_google_calendar_event',
          {
            method: 'POST',
            body: JSON.stringify(calendarEvent),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          console.error(
            'Googleカレンダーへのイベント更新に失敗しました',
            response
          );
          throw new Error('Googleカレンダーへのイベント更新に失敗しました');
        }

        const embeds = [
          {
            title: 'スケジュール変更通知',
            description: `以下の予定が変更されました。`,
            color: reservationColor.discord,
            fields: [
              {
                name: '元の予定内容',
                value: `${summary[reservation.type]}${
                  reservation.description
                }（${
                  currentUser.studio.instructors[reservation.instructorId]
                }）`,
                inline: true,
              },
              {
                name: '元の予定日時',
                value: `${reservation.startDate
                  .replace('-', '年')
                  .replace('-', '月')
                  .replace('T', '日')} - ${reservation.endDate.split('T')[1]}`,
              },
              {
                name: '新しい予定内容',
                value: `${summary[reservation.type]}${
                  reservation.description
                }（${currentUser.studio.instructors[newInstructor]}）`,
                inline: true,
              },
              {
                name: '新しい予定日時',
                value: `${newStartDate
                  .replace('-', '年')
                  .replace('-', '月')
                  .replace('T', '日')} - ${newEndDate.split('T')[1]}`,
              },
              {
                name: '変更者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: '予定ID：' + reservation.id,
            },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification(
          currentUser.studio.slug,
          embeds,
          currentUser.studio.instructors[newInstructor]
        ); // 本当はwebhookUrlはこのページ取得したときのスタジオデータから取得しないとダメ

        await sendDiscordNotification('reserve', embeds);

        await sendDiscordNotification('audit', embeds);

        setNewInstructor('');
        setNewStartDate('');
        setNewEndDate(''); // 新しいエンドデートをクリア
        onEditClose();
        setUpdating(false);
        window.location.reload();
      })
      .catch(error => {
        console.error('予約の変更に失敗しました: ', error);
      });
  };

  useEffect(() => {
    if (currentUser?.id !== userId) {
      if (!currentUser?.instructor) {
        navigate('/home');
      }
    }
  }, [currentUser, navigate, userId]);

  useEffect(() => {
    const fetchSelectedStudioAndUserReservationsData = async () => {
      const reservationsRef = collection(firestore, 'reservations');
      const q = query(
        reservationsRef,
        where('studioId', '==', studioId),
        where('userId', '==', userId),
        where('active', '==', true)
      );
      const reservationsSnapshot = await getDocs(q);
      const thisUserReservations = reservationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      const now = new Date();
      const futureReservations = thisUserReservations.filter(reservation => {
        const endDate = new Date(reservation.endDate);
        return endDate > now && reservation.type !== 'block';
      });

      const sortedReservations = futureReservations.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );

      setReservations(sortedReservations);
    };

    if (user?.reservations && !location.state?.user) {
      setReservations(user.reservations);
    } else {
      fetchSelectedStudioAndUserReservationsData();
    }
  }, [
    studioId,
    userId,
    user,
    location.state?.user,
    currentUser.instructor,
    currentUser,
  ]);

  if (canceling) return <LoadingModal text="キャンセル中..." />;
  if (updating) return <LoadingModal text="スケジュール更新中..." />;

  const getReservationColor = type => {
    switch (type) {
      case 'temporary':
        return 'pink.200';
      case 'block':
        return 'gray.200';
      case 'outsource':
        return 'purple.300';
      case 'training':
        return 'green.200';
      case 'meeting':
        return 'yellow.200';
      case 'trial':
        return 'red.300';
      default:
        return 'blue.200';
    }
  };

  const canCancelAnytime = type => {
    return type !== 'plan' && type !== 'once';
  };

  const filterReservations = reservations => {
    if (filter === 'all') return reservations;
    return reservations.filter(
      reservation => reservation.instructorId === filter
    );
  };

  const renderReservations = filteredReservations => {
    const grouped = filteredReservations.reduce((acc, reservation) => {
      const date = dayjs(reservation.startDate).format('YYYY-MM-DD');
      if (!acc[date]) acc[date] = [];
      acc[date].push(reservation);
      return acc;
    }, {});

    return Object.keys(grouped).length > 0 ? (
      Object.keys(grouped).map(date => (
        <Box key={date} mb={4}>
          <Text fontSize="md" fontWeight="bold" mb={2}>
            {dayjs(date).format('YYYY年MM月DD日')}
          </Text>
          {grouped[date].map(reservation => (
            <Flex
              borderWidth="0px"
              borderRadius="xl"
              overflow="hidden"
              py={2}
              pl={1}
              pr={3}
              mt={2}
              bg={getReservationColor(reservation.type)}
              justifyContent="space-between"
              alignItems="center"
              key={reservation.id}
            >
              <Box>
                <Text fontSize="sm">
                  {summary[reservation.type]}
                  {''}
                  {reservation.startDate.slice(11, 16)} -{' '}
                  {reservation.endDate.slice(11, 16)}{' '}
                </Text>
                <Text fontSize="xs">
                  　
                  {reservation.description && currentUser.instructor
                    ? reservation.description +
                      '（' +
                      currentUser.studio.instructors[reservation.instructorId] +
                      '）'
                    : currentUser.belongStudioIds[reservation.studioId] +
                      '（' +
                      currentUser.studio.instructors[reservation.instructorId] +
                      '）'}
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                {!isPastCancellationDeadline(reservation.startDate) ||
                canCancelAnytime(reservation.type) ? (
                  <IconButton
                    aria-label="予約をキャンセル"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    size="sm"
                    variant={'outlineBlack'}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedReservation(reservation);
                      onOpen();
                    }}
                  />
                ) : (
                  <Text fontSize="9" color={'gray'} textAlign="center" mr={0}>
                    キャンセル
                    <br />
                    期限切れ
                  </Text>
                )}
                {currentUser.instructor && (
                  <IconButton
                    aria-label="予約を編集"
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    size="sm"
                    ml={2}
                    variant={'outlineBlack'}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedReservation(reservation);
                      setNewInstructor(reservation.instructorId);
                      setNewStartDate(reservation.startDate);
                      setNewEndDate(reservation.endDate); // エンドデートをセット
                      onEditOpen();
                    }}
                  />
                )}
              </Box>
            </Flex>
          ))}
        </Box>
      ))
    ) : (
      <Text textAlign="center">現在ご予約はありません。</Text>
    );
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        mt={6}
        p={4}
        w="100%"
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        maxH="70vh"
        display="flex"
        flexDirection="column"
      >
        <Text textAlign={'center'} fontSize="lg" mb="4">
          <b>
            {user.instructor ? user.studio.name : user?.fullName + 'さま'}の予定
          </b>
        </Text>
        <Box overflowY="auto">
          {user.studio && user.studio.instructors ? (
            <Tabs
              onChange={index =>
                setFilter(
                  index === 0
                    ? 'all'
                    : Object.keys(user.studio.instructors)[index - 1]
                )
              }
              flex="1"
              display="flex"
              flexDirection="column"
            >
              <TabList overflowX="auto">
                <Tab>全体</Tab>
                {Object.values(user.studio.instructors).map(
                  (instructor, index) => (
                    <Tab key={index}>{instructor}</Tab>
                  )
                )}
              </TabList>
              <TabPanels overflowY="auto">
                <TabPanel>
                  {renderReservations(filterReservations(reservations, 'all'))}
                </TabPanel>
                {Object.keys(user.studio.instructors).map(
                  (instructorId, index) => (
                    <TabPanel key={index}>
                      {renderReservations(
                        filterReservations(reservations, instructorId)
                      )}
                    </TabPanel>
                  )
                )}
              </TabPanels>
            </Tabs>
          ) : (
            renderReservations(filterReservations(reservations, 'all'))
          )}
        </Box>
      </Box>
      <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
        予約はキャンセルするとチケットが戻ります🙆‍♀️
      </Text>
      {currentUser.instructor && (
        <Button
          size="md"
          onClick={onClickBlock}
          colorScheme="blue"
          width={'100%'}
          mt={4}
        >
          スケジュールを管理する
        </Button>
      )}

      <BackButton />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>スケジュール削除の確認</ModalHeader>
          <ModalBody>
            {selectedReservation?.startDate.replace('T', ' ')}からの
            {currentUser.studio.instructors[newInstructor]}さんの予定「
            {selectedReservation?.description}」をキャンセルしてよろしいですか？
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="red"
              mr={2}
              onClick={() => cancelReservation(selectedReservation)}
            >
              キャンセル
            </Button>
            <Button size="sm" variant="ghost" onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>予約編集の確認</ModalHeader>
          <ModalBody>
            担当インストラクターを変更：
            <Select
              placeholder="インストラクターを選択"
              value={newInstructor}
              onChange={e => setNewInstructor(e.target.value)}
            >
              {Object.entries(currentUser.studio.instructors).map(
                ([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                )
              )}
            </Select>
            新しい開始日時を選択：
            <Input
              type="datetime-local"
              value={newStartDate}
              onChange={e => {
                let startDate = new Date(e.target.value);

                // 分を00にリセット
                if (startDate.getMinutes() !== 0) {
                  startDate.setMinutes(0);
                }

                // 日本時間に変換（JSTはUTC+9時間）
                startDate.setHours(startDate.getHours() + 9);
                const formattedStartDate = startDate.toISOString().slice(0, 16);

                setNewStartDate(formattedStartDate);

                // 開始時間の1時間後を終了時間に設定、分も00にリセット
                let endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
                endDate.setMinutes(0); // 終了時間の分も00に設定
                setNewEndDate(endDate.toISOString().slice(0, 16));
              }}
              mt={2}
            />
            新しい終了日時を選択：
            <Input
              type="datetime-local"
              value={newEndDate}
              onChange={e => {
                let endDate = new Date(e.target.value);

                // 分を00にリセット
                if (endDate.getMinutes() !== 0) {
                  endDate.setMinutes(0);
                }

                // 日本時間に変換（JSTはUTC+9時間）
                endDate.setHours(endDate.getHours() + 9);
                setNewEndDate(endDate.toISOString().slice(0, 16));
              }}
              mt={2}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="blue"
              mr={2}
              onClick={() => updateReservationDetails(selectedReservation)}
            >
              変更
            </Button>
            <Button size="sm" variant="ghost" onClick={onEditClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ReservationsList;
