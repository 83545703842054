import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { signOut } from '../firebaseConfig';
import {
  Box,
  Container,
  Text,
  Image,
  Button,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useClipboard } from '@chakra-ui/react';
import { firestore, doc, getDoc, updateDoc } from '../firebaseConfig';
import Logo from './Logo';
import LoadingModal from './LoadingModal';
import { sendDiscordNotification } from '../utils/discord';

const Waiting = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSignOutOpen, setIsSignOutOpen] = useState(false);
  const [cancelling, setCanceling] = useState(false);
  const cancelRef = useRef();
  const signOutCancelRef = useRef();
  const { hasCopied, onCopy } = useClipboard(currentUser.studio.address);

  useEffect(() => {
    if (!currentUser) {
      navigate('/trial');
    } else {
      if (!currentUser.trialId) {
        navigate('/trial');
      }
      // ページを一回だけリロード
      if (!window.location.hash) {
        window.location.hash = 'reloaded';
        window.location.reload();
      }

      // 体験予約を取得
      const fetchReservation = async () => {
        try {
          const reservationDoc = await getDoc(
            doc(firestore, 'reservations', currentUser.trialId)
          );
          if (reservationDoc.exists() && reservationDoc.data().active) {
            setReservation(reservationDoc.data());
          } else {
            navigate('/reserve');
          }
        } catch (error) {
          console.error('Error fetching reservation: ', error);
        } finally {
          setLoading(false);
        }
      };

      fetchReservation();
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser.initial) {
      // reservationの日時が今の日時を過ぎていたらbeforeに遷移
      const now = new Date();
      const startDate = new Date(reservation?.startDate);
      if (startDate < now) navigate('/before');
    }
  }, [currentUser, reservation, navigate]);

  const handleCancel = async () => {
    if (currentUser && currentUser.trialId) {
      try {
        const reservationRef = doc(
          firestore,
          'reservations',
          currentUser.trialId
        );
        setCanceling(true);
        await updateDoc(reservationRef, { active: false }).then(async () => {
          if (reservation.eventId) {
            const response = await fetch(
              'https://asia-northeast1-medy-system.cloudfunctions.net/delete_google_calendar_event',
              {
                method: 'POST',
                body: JSON.stringify({
                  calendarId: `${currentUser.studio.slug}@studio-medy.com`,
                  eventId: reservation.eventId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            if (!response.ok) {
              console.error(
                'Googleカレンダーへのイベント削除に失敗しました',
                response
              );
              return;
            }
          }
          const embeds = [
            {
              title: 'キャンセル通知',
              description: `以下の予定がキャンセルされました。`,
              color: 16752762,
              fields: [
                {
                  name: '日時',
                  value: `${reservation.startDate
                    .replace('-', '年')
                    .replace('-', '月')
                    .replace('T', '日')}`,
                  inline: true,
                },
                {
                  name: '予約者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: 'キャンセル承認時刻',
              },
              timestamp: new Date().toISOString(),
            },
          ];

          await sendDiscordNotification(
            currentUser.studio.slug,
            embeds,
            currentUser.studio.instructors[reservation.instructorId]
          );

          await sendDiscordNotification('reserve', embeds);
          await sendDiscordNotification('audit', embeds);
        });
        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, { trialId: '' });
        setCanceling(false);
        navigate('/trial');
      } catch (error) {
        console.error('Error cancelling reservation: ', error);
      }
    }
  };

  const handleCancelwithSignOut = async () => {
    if (currentUser && currentUser.trialId) {
      try {
        const reservationRef = doc(
          firestore,
          'reservations',
          currentUser.trialId
        );
        setCanceling(true);
        await updateDoc(reservationRef, { active: false }).then(async () => {
          if (reservation.eventId) {
            const response = await fetch(
              'https://asia-northeast1-medy-system.cloudfunctions.net/delete_google_calendar_event',
              {
                method: 'POST',
                body: JSON.stringify({
                  calendarId: `${currentUser.studio.slug}@studio-medy.com`,
                  eventId: reservation.eventId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            if (!response.ok) {
              console.error(
                'Googleカレンダーへのイベント削除に失敗しました',
                response
              );
              return;
            }
          }
          const embeds = [
            {
              title: 'キャンセル通知',
              description: `以下の予定がキャンセルされました。`,
              color: 16752762,
              fields: [
                {
                  name: '日時',
                  value: `${reservation.startDate
                    .replace('-', '年')
                    .replace('-', '月')
                    .replace('T', '日')}`,
                  inline: true,
                },
                {
                  name: '予約者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: 'キャンセル承認時刻',
              },
              timestamp: new Date().toISOString(),
            },
          ];

          await sendDiscordNotification(
            'https://discord.com/api/webhooks/1217760107249795153/-_iAxvFOjHxrtQHMyzNh3ft8oO6x1NXPeePxO4w9msuBXPZtaPPZQ-nudGMU9R3DSDwr',
            embeds
          );
        });
        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, { trialId: '' });
        setCanceling(false);
        signOut(currentUser.auth);
        navigate('/trial');
      } catch (error) {
        console.error('Error cancelling reservation: ', error);
      }
    }
  };

  const now = new Date();
  const startDate = new Date(reservation?.startDate);
  // startDateに30分追加する
  startDate.setMinutes(startDate.getMinutes() + 30);

  // 前日21時の日時を取得
  const dayBefore = new Date(startDate);
  dayBefore.setDate(startDate.getDate() - 1);
  dayBefore.setHours(21, 0, 0, 0);

  const isCancellationDeadlinePassed = now > dayBefore;

  if (loading) return <LoadingModal text="予約情報を取得中..." />;

  if (cancelling) return <LoadingModal text="ご予約をキャンセル中..." />;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>{currentUser.fullName}さま</title>
      </Helmet>
      <Logo text="体験受付システム" />
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        width="100%"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          {reservation
            ? startDate > now
              ? 'ご予約を承りました✨'
              : 'おつかれさまでした✨'
            : 'データがありません'}
        </Text>
        {reservation ? (
          <>
            <Box mb={4} p={4} bg="gray.100" borderRadius="md" boxShadow="sm">
              <Text fontSize="lg" fontWeight="bold" color="gray.700">
                ご予約内容の詳細
              </Text>
              <Divider my={2} />
              <Text fontSize="md" color="gray.600" mb={2}>
                <Text as="span" fontWeight="bold">
                  お名前:
                </Text>{' '}
                {currentUser.fullName} さま
              </Text>
              <Text fontSize="md" color="gray.600" mb={2}>
                <Text as="span" fontWeight="bold">
                  体験日:
                </Text>{' '}
                {reservation.startDate
                  .split('T')[0]
                  .replace('-', '年')
                  .replace('-', '月')}{' '}
                日
              </Text>
              <Text fontSize="md" color="gray.600" mb={2}>
                <Text as="span" fontWeight="bold">
                  お時間:
                </Text>{' '}
                {reservation.startDate.split('T')[1].slice(0, 5)} 〜
              </Text>
              <Text fontSize="md" color="gray.600" mb={2}>
                <Text as="span" fontWeight="bold">
                  店舗名:
                </Text>{' '}
                {currentUser.studio.name}
              </Text>
              <Text fontSize="md" color="gray.600">
                <Text as="span" fontWeight="bold">
                  担当者:
                </Text>{' '}
                {currentUser.studio.instructors[reservation.instructorId]}{' '}
                インストラクター
              </Text>
            </Box>
            {now > startDate ? (
              <Box>
                <Text fontSize="sm" my="6" textAlign="center">
                  <a
                    href="/terms-of-service"
                    style={{
                      textDecoration: 'underline',
                      color: '#565656',
                    }}
                    target={{ blank: true }}
                  >
                    サービスご利用規約
                  </a>
                </Text>
                <Button
                  colorScheme="teal"
                  onClick={() => navigate('/before')}
                  size="sm"
                  width="100%"
                >
                  規約に同意して入会する
                </Button>
                <Text
                  color={'gray'}
                  fontSize="xs"
                  mt={2}
                  mb={2}
                  textAlign={'center'}
                >
                  入会手続きは３ステップです🙌
                </Text>
                <Divider mt={6} mb={4} />
                <Button
                  colorScheme="gray"
                  onClick={() =>
                    (window.location.href =
                      'https://buy.stripe.com/4gwcOW9Ec7RY4py4gh')
                  }
                  size="sm"
                  width="100%"
                  mt={2}
                >
                  体験費用のみ支払う
                </Button>
                <Text
                  color={'gray'}
                  fontSize="xs"
                  mt={2}
                  mb={2}
                  textAlign={'center'}
                >
                  お支払いがまだの場合はこちら💁‍♀️
                </Text>
              </Box>
            ) : null}
          </>
        ) : (
          <Text fontSize="md" textAlign="center" color="gray.600">
            予約情報を取得できませんでした。
          </Text>
        )}
        {now < startDate ? (
          <>
            <Image
              src="/maintenance.jpg"
              alt="待機中のイラスト"
              borderRadius="md"
            />
            <Text
              fontSize="sm"
              textAlign="center"
              color="gray.500"
              mt={4}
              fontWeight={'bold'}
            >
              下記に時間ぴったりにお越し下さい🙌
            </Text>
            <Text mt={2} px={2}>
              {currentUser.studio.address}
              <Button onClick={onCopy} ml={2} size="xs" colorScheme="teal">
                {hasCopied ? 'コピー完了' : '住所をコピー'}
                <CopyIcon ml={1} />
              </Button>
            </Text>
            <Text mt={4} ml={2} fontSize={'xs'} color="red.500">
              ※ ウェアとタオルをご持参くださいませ。
              <br />※ 体験レッスンは4,000円（税込）です。
              <br />※ 口コミで体験全額キャッシュバック中
            </Text>
            <Divider my={4} />
            <Text
              fontSize="md"
              textAlign="center"
              color="gray.800"
              mb={4}
              fontWeight={'bold'}
            >
              キャンセル・変更は前日２１時まで
            </Text>
            {isCancellationDeadlinePassed ? (
              <Text color={'red.500'} fontSize="sm" mt={2} textAlign={'center'}>
                キャンセル期限が切れました。
              </Text>
            ) : (
              <>
                <Button
                  colorScheme="red"
                  onClick={() => setIsOpen(true)}
                  size="sm"
                  width="100%"
                >
                  キャンセル・日時変更する
                </Button>
                <Text
                  color={'gray'}
                  fontSize="xs"
                  mt={2}
                  mb={2}
                  textAlign={'center'}
                >
                  変更をご希望の方は一度キャンセル下さい🙇‍♀️
                </Text>
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={() => setIsSignOutOpen(true)}
                  size="sm"
                  width="100%"
                  mt={2}
                >
                  別のメールアドレスで取り直す
                </Button>
                <Text color={'gray'} fontSize="xs" mt={2} textAlign={'center'}>
                  別のメールアドレスでお取りする方はこちら🙆‍♀️
                </Text>
              </>
            )}
          </>
        ) : null}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsOpen(false)}
        >
          <AlertDialogOverlay
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <AlertDialogContent mx={4} my="auto">
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                体験予約のキャンセル
              </AlertDialogHeader>

              <AlertDialogBody>
                本当に体験予約をキャンセルしますか？
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                  いいえ
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  はい
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isSignOutOpen}
          leastDestructiveRef={signOutCancelRef}
          onClose={() => setIsSignOutOpen(false)}
        >
          <AlertDialogOverlay
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <AlertDialogContent mx={4} my="auto">
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                別のメールアドレスで取り直し
              </AlertDialogHeader>

              <AlertDialogBody>
                本当に現在の予約をキャンセルし、別のメールアドレスで取り直しますか？
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={signOutCancelRef}
                  onClick={() => setIsSignOutOpen(false)}
                >
                  いいえ
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleCancelwithSignOut}
                  ml={3}
                >
                  はい
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
      {currentUser.trialPaid && (
        <Text fontSize={12} color={'gray'} mb={4} mx={5}>
          <b>キャンセルポリシー</b>
          <br />
          前日２１時を過ぎた場合、キャンセル操作ができなくなります。この場合、キャンセル料１００％となり体験費用の返金がされません。
          <br />
          <br />
          変更や取消のためにキャンセルをする場合は前もってお手続きください。
          <br />
          <br />
          日時変更ではなく完全に体験予約を取り消す場合は、キャンセル後に公式LINEにてお伝えください。キャンセル期限内の場合のみ返金手続きをさせていただきます。
        </Text>
      )}
    </Container>
  );
};

export default Waiting;
