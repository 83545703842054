import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  if (!currentUser) {
    // 未認証の場合、現在のパスをlocalStorageに保存
    localStorage.setItem('preLoginUrl', window.location.pathname);
    // ログインページにリダイレクト
    return <Navigate replace to="/login" />;
  }

  if (currentUser?.maintenanceMode) {
    navigate('/maintenance');
  }

  // 認証済みの場合は、子コンポーネントをそのまま表示
  return children;
};

export default PrivateRoute;
