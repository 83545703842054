import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Container,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import LoadingModal from '../components/LoadingModal';
import BackButton from './BackButton';
import {
  firestore,
  collection,
  doc,
  getDoc,
  updateDoc,
} from '../firebaseConfig';

const AddShiftPage = () => {
  const { currentUser } = useAuth();
  const { studioId, userId } = useParams();

  const [day, setDay] = useState('月');
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [shiftToChange, setShiftToChange] = useState(null); // シフト変更のための状態

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async e => {
    e.preventDefault();
    const studioRef = doc(collection(firestore, 'studios'), studioId);

    const newShifts = {};
    for (let hour = parseInt(startHour); hour <= parseInt(endHour); hour++) {
      const shiftKey = `${day}${hour}`;
      if (!newShifts[`shifts.${shiftKey}`]) {
        newShifts[`shifts.${shiftKey}`] = [];
      }
      newShifts[`shifts.${shiftKey}`].push(userId);
    }
    await updateDoc(studioRef, newShifts);

    setDay('月');
    setStartHour('');
    setEndHour('');
    window.location.reload(); // ページをリロード
  };

  const handleShiftClick = (day, hour, isAdding) => {
    setShiftToChange({ day, hour, isAdding });
    onOpen();
  };

  const confirmShiftChange = async () => {
    const { day, hour, isAdding } = shiftToChange;
    const studioRef = doc(collection(firestore, 'studios'), studioId);
    const shiftKey = `${day}${hour}`;
    const shiftPath = `shifts.${shiftKey}`;
    isAdding ? setAdding(true) : setRemoving(true);

    const studioDoc = await getDoc(studioRef);
    if (studioDoc.exists()) {
      const studioData = studioDoc.data();
      let updatedShifts;
      if (studioData.shifts && studioData.shifts[shiftKey]) {
        if (isAdding) {
          updatedShifts = [...studioData.shifts[shiftKey], userId];
        } else {
          const userShiftsCount = Object.values(studioData.shifts)
            .flat()
            .filter(uid => uid === userId).length;
          if (userShiftsCount <= 1) {
            alert(
              '予約受付枠の数が25個未満になるため削除できません。他の受付枠を追加してから削除してください。'
            );
            setRemoving(false);
            return;
          }
          updatedShifts = studioData.shifts[shiftKey].filter(
            uid => uid !== userId
          );
        }
      } else if (isAdding) {
        updatedShifts = [userId];
      }

      await updateDoc(studioRef, {
        [shiftPath]: updatedShifts,
      });
      window.location.reload(); // ページをリロード
    }

    setAdding(false);
    setRemoving(false);
    onClose();
  };

  const groupShiftsByInstructor = shifts => {
    const instructorShifts = {};

    Object.entries(shifts).forEach(([key, value]) => {
      const [day, hour] = key.match(/([^\d]+)(\d+)/).slice(1, 3);
      value.forEach(instructorId => {
        if (!instructorShifts[instructorId]) {
          instructorShifts[instructorId] = {
            月: [],
            火: [],
            水: [],
            木: [],
            金: [],
            土: [],
            日: [],
          };
        }
        instructorShifts[instructorId][day].push(parseInt(hour));
      });
    });

    return instructorShifts;
  };

  const instructorShifts = groupShiftsByInstructor(
    currentUser.studio.shifts || {}
  );

  const hours = Array.from({ length: 23 - 9 + 1 }, (_, i) => i + 9);

  if (adding) return <LoadingModal text="予約の受付枠を追加中..." />;
  if (removing) return <LoadingModal text="予約の受付枠を削除中..." />;

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="md" textAlign="center" mt="0px" mb="20px">
        幡ヶ谷スタジオの予約受付枠設定
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {Object.entries(instructorShifts).map(([instructorId, days]) =>
          instructorId === userId ? (
            <Box key={instructorId} mb={6}>
              <Heading as="h2" size="sm" textAlign="center" mb={4}>
                {currentUser.studio.instructors[userId]}さんの予約受付枠
              </Heading>
              <Table variant="simple" size="xs">
                <Thead>
                  <Tr>
                    <Th style={{ textAlign: 'center' }}>開始</Th>
                    <Th style={{ textAlign: 'center' }}>月</Th>
                    <Th style={{ textAlign: 'center' }}>火</Th>
                    <Th style={{ textAlign: 'center' }}>水</Th>
                    <Th style={{ textAlign: 'center' }}>木</Th>
                    <Th style={{ textAlign: 'center' }}>金</Th>
                    <Th style={{ textAlign: 'center' }}>土</Th>
                    <Th style={{ textAlign: 'center' }}>日</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {hours.map(hour => (
                    <Tr key={hour}>
                      <Td style={{ textAlign: 'center' }}>{hour}時</Td>
                      {['月', '火', '水', '木', '金', '土', '日'].map(day => (
                        <Td
                          key={day}
                          onClick={() =>
                            handleShiftClick(
                              day,
                              hour,
                              !days[day].includes(hour)
                            )
                          }
                          style={{ cursor: 'pointer', textAlign: 'center' }}
                        >
                          {days[day].includes(hour) ? '○' : '+'}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : null
        )}
      </Box>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Heading as="h2" size="sm" textAlign="center" mb={4}>
          予約受付枠をまとめて追加
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>インストラクター</FormLabel>
              <Select value={userId}>
                <option key={'0'} value={userId}>
                  {currentUser.studio.instructors[userId]}
                </option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>曜日</FormLabel>
              <Select value={day} onChange={e => setDay(e.target.value)}>
                <option value="月">月</option>
                <option value="火">火</option>
                <option value="水">水</option>
                <option value="木">木</option>
                <option value="金">金</option>
                <option value="土">土</option>
                <option value="日">日</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>最初のレッスンが開始する時間</FormLabel>
              <Input
                type="number"
                value={startHour}
                onChange={e => setStartHour(e.target.value)}
                placeholder="例: 9"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>最後のレッスンが終了する時間</FormLabel>
              <Input
                type="number"
                value={endHour}
                onChange={e => setEndHour(e.target.value)}
                placeholder="例: 16"
              />
            </FormControl>
            <Button colorScheme="blue" type="submit">
              追加
            </Button>
          </VStack>
        </form>
      </Box>
      <BackButton />

      {/* シフト変更の確認モーダル */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>シフト変更の確認</ModalHeader>
          <ModalBody>
            このシフトを
            {shiftToChange?.isAdding ? '追加' : '削除'}
            しますか？
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={confirmShiftChange}>
              はい
            </Button>
            <Button variant="ghost" onClick={onClose}>
              いいえ
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default AddShiftPage;
