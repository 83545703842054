import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Text,
  Container,
  Heading,
  Flex,
  Spinner,
  Badge,
  Button,
  Input,
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import BackButton from './BackButton';
import { firestore, doc, getDoc, updateDoc } from '../firebaseConfig'; // updateDocを追加

const Studio = () => {
  const { studioId } = useParams();
  const [studio, setStudio] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isStatsVisible, setIsStatsVisible] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState('');
  const [announce, setAnnounce] = useState(''); // アナウンス用の状態を追加
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const onClickShift = () =>
    navigate(`/studios/${studioId}/instructors/${selectedInstructor}/shift`);
  const onClickBlock = () =>
    navigate(`/studios/${studioId}/instructors/${currentUser.uid}/block`);

  useEffect(() => {
    const fetchStudioAndUsers = async () => {
      if (studioId) {
        const studioDocRef = doc(firestore, 'studios', studioId);
        const studioDocSnap = await getDoc(studioDocRef);

        if (studioDocSnap.exists()) {
          const studioData = studioDocSnap.data();
          setStudio(studioData);
          console.log(studioData);

          if (studioData.users && Array.isArray(studioData.users)) {
            const userPromises = studioData.users.map(userRef =>
              getDoc(userRef)
            );
            const userDocs = await Promise.all(userPromises);
            const usersData = userDocs
              .filter(docSnap => docSnap.exists() && !docSnap.data().instructor)
              .map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
            setUsers(usersData);
          } else {
            console.log('このスタジオにはユーザーが所属していません。');
          }

          // アナウンスの読み込み
          const announceRef = doc(firestore, 'settings', 'announce');
          const announceDoc = await getDoc(announceRef);
          if (announceDoc.exists()) {
            const announceData = announceDoc.data()[studioId] || '';
            setAnnounce(announceData); // アナウンスを状態にセット
          }
        } else {
          console.log('スタジオが見つかりません。');
        }
      }
    };

    fetchStudioAndUsers();
  }, [studioId]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const handleAnnounceChange = event => {
    setAnnounce(event.target.value);
  };

  const handleUpdateAnnounce = async () => {
    const announceRef = doc(firestore, 'settings', 'announce');
    await updateDoc(announceRef, { [studioId]: announce }); // アナウンスを更新
    window.location.reload(); // アナウンスの変更をリアルタイムに反映するためにページをリロード
  };

  const filteredUsers = users.filter(user =>
    user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleStatsVisibility = () => {
    setIsStatsVisible(!isStatsVisible);
  };

  if (!studio) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner mr={3} />
        <Text fontSize="xl">データ読み込み中...</Text>
      </Flex>
    );
  }

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="" mb="">
        medy講師専用システム
      </Heading>

      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={0} mt={4}>
        <Box
          mb={4}
          borderWidth="0px"
          borderRadius="xl"
          backgroundColor="#fdfdfd"
          p={4}
        >
          <Text textAlign={'center'} mt={0}>
            <b>スタジオ予定関係</b>
          </Text>
          <Button
            size="md"
            onClick={onClickBlock}
            colorScheme="teal"
            width={'100%'}
            my={4}
          >
            スケジュールを管理する
          </Button>
          {/* アナウンスの表示と更新 */}
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            予約画面でのアナウンス表示
          </Text>
          <Textarea
            placeholder="アナウンスを入力してください"
            value={announce}
            onChange={handleAnnounceChange}
            backgroundColor={'#fdfdfd'}
            mb={2}
          />
          <Button
            colorScheme="blue"
            onClick={handleUpdateAnnounce}
            width="100%"
          >
            アナウンスを更新
          </Button>
        </Box>
        <UsersList
          users={filteredUsers}
          studioId={studioId}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />
        <Box
          my={4}
          borderWidth="0px"
          borderRadius="xl"
          backgroundColor="#fdfdfd"
          p={4}
        >
          <Text
            fontSize="sm"
            fontWeight="bold"
            mt={0}
            mb={0}
            cursor="pointer"
            onClick={toggleStatsVisibility}
          >
            {studio.name + ' の情報 '}
            {isStatsVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Text>
          {isStatsVisible && (
            <>
              <Text fontSize="xs" mt={4}>
                〒{studio.postalCode}
              </Text>
              <Text fontSize="xs">{studio.address}</Text>
              <UserStatsTable currentUser={currentUser} users={users} />
            </>
          )}
        </Box>
        {currentUser.admin && (
          <Box
            mt={4}
            borderWidth="0px"
            borderRadius="xl"
            backgroundColor="#fdfdfd"
            p={4}
          >
            <Text textAlign={'center'} mt={0}>
              <b>管理者メニュー</b>
            </Text>
            <Select
              placeholder="インストラクターを選択"
              value={selectedInstructor}
              onChange={e => setSelectedInstructor(e.target.value)}
              backgroundColor={'#fdfdfd'}
              mt={4}
            >
              {Object.entries(currentUser.studio.instructors).map(
                ([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                )
              )}
            </Select>
            <Button
              size="md"
              onClick={onClickShift}
              colorScheme="teal"
              width={'100%'}
              mt={4}
              isDisabled={!selectedInstructor}
            >
              予約の受付枠を設定する
            </Button>
            <Button
              size="md"
              onClick={() => navigate(`/studios/${studioId}/business`)}
              colorScheme="blue"
              width={'100%'}
              mt={4}
            >
              経営の指標を確認する
            </Button>
          </Box>
        )}
      </Box>
      <BackButton path="/manage" />
    </Container>
  );
};

const UserStatsTable = ({ currentUser, users }) => {
  const countUsersByPlan = (plan, sex) => {
    return users.filter(
      user =>
        user.plan === plan && user.status && user.active && user.sex === sex
    ).length;
  };

  const analizeUsers = sex => {
    const fixedUsersCount = users.filter(
      user => user?.fixies?.length > 0 && user.sex === sex
    ).length;
    const activeUsersCount = users.filter(
      user => user.status && user.active && user.sex === sex
    ).length;
    const planUsersCount = users.filter(
      user =>
        [2, 4, 6, 8].includes(user.plan) &&
        user.status &&
        user.active &&
        user.sex === sex
    ).length;

    // planごとの人数とplanを掛け算して足し合わせた当月予測レッスン数
    const totalPlanUsersCount = users
      .filter(user => user.status && user.active && user.sex === sex)
      .map(user => user.plan)
      .reduce((acc, plan) => acc + plan, 0);

    // planごとの単価
    const planPrices = {
      female: {
        2: 8800,
        4: 8800,
        6: 8250,
        8: 7700,
      },
      male: {
        2: 9900,
        4: 9900,
        6: 9350,
        8: 8800,
      },
    };

    // planの単価と人数を掛け算した売上
    let planSales = 0;
    users
      .filter(
        user =>
          [2, 4, 6, 8].includes(user.plan) &&
          user.status &&
          user.active &&
          user.sex === sex
      )
      .map(user => (planSales += planPrices[sex][user.plan] * user.plan));
    return {
      fixedUsersCount,
      activeUsersCount,
      planUsersCount,
      totalPlanUsersCount,
      planSales,
    };
  };
  const female = analizeUsers('female');
  const male = analizeUsers('male');

  const countValidShifts = shifts => {
    let count = 0;
    for (const key in shifts) {
      if (shifts[key] && shifts[key].length > 0) count++;
    }
    return count * 4;
  };

  return (
    <Table variant="simple" my={2} fontSize={'xs'} backgroundColor={'#fdfdfd'}>
      <Tbody>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}></Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            女性
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            男性
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            プラン登録者総数
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {female['planUsersCount']}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {male['planUsersCount']}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            　月2プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(2, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(2, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            　月4プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(4, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(4, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            　月6プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(6, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(6, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            　月8プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(8, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(8, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            　専用枠利用
          </Td>
          <Td height="40px" padding="8px">
            {female['fixedUsersCount']}名
          </Td>
          <Td height="40px" padding="8px">
            {male['fixedUsersCount']}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            回数券利用（休会含む）
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {countUsersByPlan(0, 'female')}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {countUsersByPlan(0, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            会員数合計
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {female['activeUsersCount']}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {male['activeUsersCount']}名
          </Td>
        </Tr>
        {currentUser.admin && (
          <>
            <Tr>
              <Td height="40px" padding="8px" fontWeight={'bold'}>
                レッスン数予測
              </Td>
              <Td colSpan="2" height="40px" padding="8px" fontWeight={'bold'}>
                {female['totalPlanUsersCount'] + male['totalPlanUsersCount']}/
                {countValidShifts(currentUser.studio.shifts)} 回
              </Td>
            </Tr>
            <Tr>
              <Td height="40px" padding="8px" fontWeight={'bold'}>
                当月売上高予測（回数券除く）
              </Td>
              <Td colSpan="2" height="40px" padding="8px" fontWeight={'bold'}>
                {/* 3桁区切り */}
                {(female['planSales'] + male['planSales']).toLocaleString()}円
              </Td>
            </Tr>
          </>
        )}
      </Tbody>
    </Table>
  );
};

const UsersList = ({ users, studioId, searchQuery, onSearchChange }) => {
  const navigate = useNavigate();
  const handleUserClick = (userId, flag) => {
    if (!flag) return;
    navigate(`/studios/${studioId}/users/${userId}`);
  };
  // 現会員、未入会、退会者のユーザーをフィルタリング
  const activeUsers = users.filter(user => user.active && user.status);
  const trialUsers = users.filter(
    user => user.active && !user.status && user?.trialId?.length > 0
  );
  const pendingUsers = users.filter(
    user => !user.status && !user?.trialId?.length > 0
  );
  const inactiveUsers = users.filter(user => !user.active && user.status);
  return (
    <div>
      <Box
        key={'search'}
        borderWidth="1px"
        borderRadius="xl"
        p={4}
        mt={0}
        backgroundColor={'#fdfdfd'}
      >
        <Text textAlign={'center'} mb={4}>
          <b>所属会員の一覧</b>
        </Text>
        <Input
          placeholder="氏名で検索"
          backgroundColor={'#fdfdfd'}
          value={searchQuery}
          onChange={onSearchChange}
          mb={2}
        />

        {/* タブの追加 */}
        <Tabs>
          <TabList overflowX={'auto'}>
            <Tab>会員（{activeUsers.length}）</Tab>
            <Tab>体験（{trialUsers.length}）</Tab>
            <Tab>保留（{pendingUsers.length}）</Tab>
            <Tab>退会（{inactiveUsers.length}）</Tab>
          </TabList>

          <TabPanels>
            {/* 現会員のリスト */}
            <TabPanel>
              <UserListPanel
                users={activeUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 体験のリスト */}
            <TabPanel>
              <UserListPanel
                users={trialUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 検討のリスト */}
            <TabPanel>
              <UserListPanel
                users={pendingUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 退会者のリスト */}
            <TabPanel>
              <UserListPanel
                users={inactiveUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

// 共通のユーザーリストパネルコンポーネントを定義
const UserListPanel = ({ users, handleUserClick }) => (
  <Box
    height="240px" // 固定の高さ
    overflowY="scroll" // 縦スクロールを有効にする
    mt={1}
  >
    {users.length > 0 ? (
      users.map(user => (
        <Box
          key={user.id}
          borderWidth="1px"
          borderRadius="xl"
          p={4}
          mt={2}
          backgroundColor={user.active ? '#fdfdfd' : 'gray.300'}
          onClick={() => handleUserClick(user.id, user.active)}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xs">
              <Badge
                fontSize="9px"
                px={1}
                py="0.5"
                mr="1"
                mb="0.5"
                bg="gray.100"
              >
                月{user.plan}
              </Badge>
              {user.fullName}　
              {user.active && user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="green.100"
                >
                  継続中
                </Badge>
              ) : !user.active && user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.300"
                >
                  退会済
                </Badge>
              ) : !user.active && !user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.100"
                >
                  未入会
                </Badge>
              ) : user.initial ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.100"
                >
                  手続中
                </Badge>
              ) : user?.trialId?.length > 0 ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.500"
                  color="white"
                >
                  体験
                </Badge>
              ) : (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="yellow.100"
                >
                  検討
                </Badge>
              )}
              {user?.fixies?.length > 0 ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="green.300"
                >
                  固定済
                </Badge>
              ) : (
                <></>
              )}
            </Text>
          </Flex>
        </Box>
      ))
    ) : (
      <Text>ユーザーが存在しません。</Text>
    )}
  </Box>
);

export default Studio;
